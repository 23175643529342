<!-- Common vue component used for multiselect dropdown menu with search -->

<template>
<span class="search-dropdown-container">
    <span class="header" @click="dropdownToggle">
        <span class="active-item" :style="itemWidth">
            {{title}}
            {{filterValues.length > 0 ? `(${filterValues.length})` : ""}}
        </span>
        <i
            class="pi"
            :class="{
                'pi-chevron-down': !dropdownActive,
                'pi-chevron-up': dropdownActive
            }"
        />
    </span>
    <div class="content-container">
        <div class="dropdown-container" v-if="dropdownActive">
            <input class="search-bar"
                type="text"
                placeholder="search"
                v-model="searchString"
                ref="textInput"
            >
            <ul class="item-list-container">
                <div class="selected-items" v-if="filterValues.length > 0">
                    <li class="item selected"
                        v-for="item in filterValues" :key="item"
                        @click.stop="dropdownItemSelected('remove', item)"
                    >
                        <span @mouseenter="setHoverLabelIfOverflowing">
                            {{item}}
                        </span>
                        <i class="pi pi-check-circle"/>
                    </li>
                </div>
                <li class="item"
                    v-for="item in filteredItemList" :key="item"
                    @click.stop="dropdownItemSelected('add', item)"
                    @mouseenter="setHoverLabelIfOverflowing"
                >
                    {{item}}
                </li>
                <div v-if="filteredItemList.length === 0" class="none-found">
                    None found
                </div>
            </ul>
        </div>
    </div>
</span>
</template>

<script>
export default {
    name: "MultiSelectDropdown",
    props: {
        title: {
            type: String,
            required: true
        },
        // a list of options for the dropdown
        itemList: {
            type: Array,
            required: true
        },
        // selected filter values
        filterValues: {
            type: Array,
            required: true
        },
        width: {
            type: Number,
            required: false,
            default: 10
        }
    },
    emits: ["update:filterValues"],
    data() {
        return {
            // the search string input by the user
            searchString: "",
            // whether or not the dropdown is displayed
            dropdownActive: false
        }
    },
    computed: {
        /**
         * Filters a list of strings based on a search string
         * 
         * A string is filtered out if it doesn't contain the search string
         *
         * @returns {Array} - the filtered array
         */
        filteredItemList() {
            const filteredList = this.itemList.filter((item) => {
                if (!item) {
                    return false
                }
                const matchesSearchString = item.toUpperCase().includes(
                    this.searchString.toUpperCase()
                )
                const notInFilterValues = this.filterValues.indexOf(item) === -1
                return matchesSearchString && notInFilterValues
            })
            return filteredList;
        },
        itemWidth() {
            return {
                "width": `${this.width}rem`
            }
        }
    },
    methods: {
        /**
         * Adds/Removes an item from the filter value array
         * 
         * @param {String} addOrRemove - whether to add or remove the selected
         * item from the filter values. can be 'add' or 'remove'
         * @param {String} item - The string selected in the dropdown menu
         */
        dropdownItemSelected(addOrRemove, item) {
            let newFilterValues = this.filterValues
            if (addOrRemove === "add") {
                if (newFilterValues.indexOf(item) === -1) {
                    newFilterValues.push(item)
                }
            }
            else if (addOrRemove === "remove") {
                const index = newFilterValues.indexOf(item)
                if (index >= 0) {
                    newFilterValues.splice(index, 1)
                }
            }
            this.$emit("update:filterValues", newFilterValues)
        },
        /**
         * Toggles display of he dropdown menu
         */
        dropdownToggle() {
            this.searchString = ""
            this.dropdownActive = !this.dropdownActive
            // auto-focuses text input when opening dropdown menu
            if (this.dropdownActive) {
                this.$nextTick(() => {this.$refs.textInput.focus()})
            }
        },
        /**
         * checks if click event occured outside element and closes dropdown
         */
        dropdownOff(event) {
            if (!this.$el.contains(event.target)) {
                this.searchString = ""
                this.dropdownActive = false
            }
        },
        /**
         * Adds the full text of an element as a hover label if it is overflowing
         * @param {MouseEvent}
         */
        setHoverLabelIfOverflowing(event) {
            let element = event.target;
            if (element.offsetWidth < element.scrollWidth && !element.title) {
                element.title = element.textContent
            }
        }
    },
    mounted () {
        document.addEventListener('click', this.dropdownOff)
    },
    beforeUnmount () {
        document.removeEventListener('click',this.dropdownOff)
    }
}
</script>

<style scoped>
/**
* .search-dropdown-container is 'inline-block' to be a containing block for
* .content-container. .content-container is 'relative' to be a container block
* for .dropdown-container.
*
* this is done so that the dropdown container matches it's width to the content
* box of .search-dropdown-container, even if .search-dropdown-container has
* margin/padding applied by a parent.
* (this is because the containing block for 'absolute' is the PADDING box of
* the nearest 'absolute'/'relative'/'fixed' positioned parent)
*/
.search-dropdown-container {
    /* To contain the width of the 'absolute' positioned dropdown menu */
    display: inline-block;
}
.content-container {
    /* forces dropdown-container to respect padding/margin of container */
    position: relative;
}
.header {
    display: inline-flex;
    align-items: center;
    border-radius: 0.5rem;
    border: solid;
    border-color: rgba(157, 160, 165, 0.63);
    border-width: 1px;
    padding: 0.25rem 1rem 0.25rem 1rem;
}
.active-item {
    display: inline-block;
    font-size: 1rem;
}
.header > i {
    /* margin-right: 0.8rem; */
    font-size: 0.8rem;
}
.header:hover, .dropdown-content:hover {
    cursor: pointer;
}

.dropdown-container {
    position: absolute;
    width: 100%;
    background-color: var(--colour-4);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius:  0 0 0.5rem 0.5rem;
    border: solid;
    border-color: rgba(157, 160, 165, 0.63);
    border-width: 1px;
    font-size: 1rem;
    z-index: 999;
    color: var(--colour-6);
    font-weight: 400;
    padding: 0.75rem 1rem 0 1rem;
}
.search-bar {
    margin-bottom: 0.5rem;
    color: inherit;
    font-weight: inherit;
    font-size: inherit;
    font-family: inherit;
    width: 100%;
    height: 2rem;
    border-style: solid;
    border-color: var(--colour-2);
    border-radius: 0.5rem;
    border-width: 1px;
    padding-left: 0.5rem;
    outline: 0;
}
.item-list-container {
    overflow: auto;
    overflow-x: hidden;
    max-height: 30vh;
    margin: 0;
    padding: 0;
}
.item-list-container:hover {
    cursor: pointer;
}
.item {
    list-style-type: none;
    padding: 0.5rem 0 0.5rem 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.item.selected {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
}
.item.selected > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.selected-items {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: rgba(157, 160, 165, 0.63);
}
.item:hover, .item.active {
    font-weight: bold;
}
.none-found {
    padding: 0.5rem 0 0.5rem 0.5rem;
    white-space: nowrap;
    overflow: hidden;
}
.none-found:hover {
    cursor: default;
}
</style>
