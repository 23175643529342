/**
 * Namespace for formatters
 */
class Formatter {


    /**
     * Converts a value and unit to correct format for singular/plural
     * 
     * @param {Number} value - the reference number to convert
     * @param {String} unit - the singular form of the unit to use
     * 
     * @returns {string} the value with formatted unit
     */
    static formatUnit(value, unit) {
        if (value !== 1) {
            unit += "s"
        }
        return `${value} ${unit}`
    }


    /**
     * Formats a date string
     * 
     * @param {String} date - a date string (yyyy-mm-dd) to be formatted
     * @returns {String} - a formatted date string as: dd/mm/yyyy
     */
    static formatDate(date) {
        if (date == null) {return "Unknown"}
        else {
            date = new Date(date)
            return date.toLocaleDateString()
        }
    }

    /**  
     * Formats the reason for the speed restriction
     * 
     * @param {String} reason - a reason for the speed restriction (e.g. Track - Condition of Ballast)
     * @returns {String} - returns html div string containing reason for speed restriction.
    **/
    static formatReason(reason) {

        if (!reason) return "";

        // split string in 2 just after the break string and remove leading space after breakstring
        let split_reason = reason.split(" - ")
        return `
            <div><b>${split_reason[0]}</b></div>
            <div>${split_reason[1]}</div>
        `
    }
}

export default Formatter