<!--
    Operator breakdown card on Analysis tab of Individual page
-->

<template>
<Card :expandable="false">
    <template #header-left>
        Operator breakdown
    </template>
    <template #body>
        <div class="body">
            <div class="table">
                <p id="table-explanation">
                    Impact is calculated on groups of trains that have the same operator, stopping pattern, and route (either berth or stanox). Below are plots showing the difference in runtime distribution before and during the SR. To view the plot for any of these groups, click on its row.
                </p>
                <TsrPeriods :reference="referenceNumber"/>
                <div v-if="operatorLoading">LOADING</div>
                <!-- Loop through each row (toc) in the operator impact data -->
                <div v-else v-for="tocRow in operatorImpactData" :key="tocRow.toc" class="toc-row">
                    <div class="toc-data" @click="dropdownButtonClick(tocRow.toc)" 
                        :class="{open: dropdownOpen[tocRow.toc], 'has-top-gap': hasTopGap(tocRow.toc)}"
                    >
                        <span class="left">
                            <span class="toc-name">{{tocRow.toc}}</span>
                            <i class="pi pi-circle-on"/>
                            <span class="toc-impact">{{formatUnit(tocRow.daily_impact_minutes, "minute")}}</span>
                        </span>
                        <span class="right">
                            <span v-html="formatPunctualityInfo(tocRow.would_have_been_ontime, tocRow.would_have_been_t3)"/>
                            <button class="dropdown-button">
                                <i v-if="!dropdownOpen[tocRow.toc]" class="pi pi-chevron-down"></i>
                                <i v-else class="pi pi-chevron-up"></i>
                            </button>
                        </span>
                    </div>
                    <div v-if="dropdownOpen[tocRow.toc]" class="service-data">
                        <div v-for="serviceRow in filteredOperatorBreakdown(tocRow.toc)" 
                            :key="serviceRow.unique_group_id" class="service-row"
                            :class="{active: serviceRow.unique_group_id === activeService}"
                            @click="serviceClick(serviceRow)"
                        >
                            <span>
                                <span class="service-type">{{serviceRow.service_type}}</span>
                                <span class="service-impact">{{formatServiceMinutes(serviceRow.daily_impact_minutes)}}</span>
                                <span class="train-info">
                                    {{formatTrainInfo(
                                        formatImpactPerTrain(serviceRow.impact_per_train_s),
                                        formatTrainsPerDay(serviceRow.avg_daily_train_count)
                                    )}}
                                </span>
                            </span>
                            <i v-if="serviceRow.unique_group_id === activeService" class="radio pi pi-circle-on"/>
                            <i v-else class="radio pi pi-circle-off"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="graphs">
                <div v-if="activeServiceData !== null" class="graph-title">
                    <span class="segment">
                        <div class="header">{{ activeServiceData.toc }}</div>
                        <div class="text">Operator</div>
                    </span>
                    <div class="spacer"></div>
                    <span class="segment">
                        <div class="header overflow" :title="formatList(activeServiceData.trust_section_stopping_pattern_concat)">{{ formatList(activeServiceData.trust_section_stopping_pattern_concat) }}</div>
                        <div class="text">Stopping pattern</div>
                    </span>
                    <div class="spacer"></div>
                    <span class="segment">
                        <div class="header overflow" :title="formatList(activeServiceData.route)">{{ formatList(activeServiceData.route) }}</div>
                        <div class="text">Route</div>
                    </span>
                    <div class="spacer"></div>
                    <span class="segment">
                        <div class="header">{{ activeServiceData.impact_per_train_s }} s</div>
                        <div class="text">Impact per train</div>
                    </span>
                    <div class="spacer"></div>
                    <span class="segment">
                        <div class="header">{{ formatUnit(activeServiceData.avg_daily_train_count, "train") }}</div>
                        <div class="text">per day</div>
                    </span>
                </div>
                <div v-if="graphLoading">LOADING</div>
                <div v-else v-html="graphDiv" style="height: 100%;width: 100%;"></div>
            </div>
        </div>
    </template>
</Card>
</template>

<script>
import Card from "../../common/Card.vue"
import axios from "axios";
import TsrPeriods from "./TsrPeriods.vue"

export default {
    name: "OperatorBreakdown",
    components: {
        Card,
        TsrPeriods
    },
    data() {
        return {
            operatorLoading: true,
            operatorBreakdownData: null,
            operatorImpactData: null,
            dropdownOpen: null,
            activeService: null,
            activeServiceData: null,
            graphLoading: false,
            graphDiv: null
        }
    },
    computed: {
        referenceNumber() {
            // returns reference number for currently selected speed restrictions, or 
            //  for the first speed restriction in table if one hasn't been selected

            // current reference number for currently selected speed restriction
            let ref = this.$store.state.activeIndividualSpeedRestriction
            // TODO: sort out what happens if no reference is selected
            return ref
        },
    },
    watch: {
        graphLoading: (function(val) {
            // runs the plotly graph script once the virtual DOM has loaded
            if (!val) {
                this.$nextTick(
                    () => {
                        // get script
                        let script = document.querySelector(".graphs script").innerHTML
                        // run script
                        eval(script)
                    }
                )
            }
        }),
        referenceNumber: function() {
            this.getData()
        }
    },
    methods: {
        filteredOperatorBreakdown(toc) {
            return this.operatorBreakdownData.filter(row => row.toc === toc && row.daily_impact_minutes !== null)
        },
        createDropdownOpenObject() {
            let dropdownOpen = {}
            for (let row of this.operatorImpactData) {
                dropdownOpen[row.toc] = false
            }
            this.dropdownOpen = dropdownOpen
        },
        dropdownButtonClick(toc) {
            this.dropdownOpen[toc] = !this.dropdownOpen[toc]
        },
        serviceClick(serviceRow) {
            let group_id = serviceRow.unique_group_id
            if (group_id === this.activeService) {
                return
            }
            else {
                this.activeService = group_id
                this.activeServiceData = serviceRow
            }
            this.getGraphData(serviceRow)
        },
        formatUnit(impactNumber, unit) {
            // Reformates time loss values to either add 'unit/s' or replace with 'unknown'
            //   but displayed on the table as 'Unknown'
            if (impactNumber == null) {
                impactNumber = "Unknown"
            }
            else {
                impactNumber = impactNumber.toLocaleString("en-UK")
                if (impactNumber == 1) {
                    impactNumber += " " + unit
                }
                else {
                    impactNumber += " " + unit + "s"
                }
            }
            return impactNumber
        },
        formatList(list) {
            let formattedList = String(Array.from(new Set(list)))
                .replaceAll(",", ", ")
                .replaceAll(/to(?=(?:[A-Z])|(?:stop)|(?:pass))/g, " to ")
            return formattedList
        },
        formatServiceMinutes(impactNum) {
            if (impactNum == null) {
                impactNum = "Unknown"
            }
            else {
                impactNum = impactNum.toLocaleString("en-UK")
                impactNum += "m per day"
            }
            return impactNum
        },
        formatImpactPerTrain(impactNum) {
            if (impactNum == null) {
                impactNum = "Unknown"
            }
            else {
                impactNum = impactNum.toLocaleString("en-UK")
                impactNum += "s per train"
            }
            return impactNum
        },
        formatTrainsPerDay(count) {
            if (count == null) {
                count = "Unknown"
            }
            else {
                count = count.toLocaleString("en-UK")
                if (count == 1) {
                    count += " train per day"
                }
                else {
                    count += " trains per day"
                }
            }
            return count
        },
        formatTrainInfo(trainImpact, trainCount) {
            if (trainImpact === "Unknown" && trainCount === "Unknown") {
                return "Unknown"
            }
            else {
                return `${trainImpact} | ${trainCount}`
            }
        },
        formatPunctualityInfo(formattedOntime, formattedT3) {
            if (formattedOntime == null && formattedT3 !== null) {
                return `<span class="punctuality-text"><span class="percentage">${formattedT3}%</span> T-3</span>`
            }
            else if (formattedOntime !== null && formattedT3 == null) {
                return `<span class="punctuality-text"><span class="percentage">${formattedOntime}%</span> On-Time</span>`
            }
            else if (formattedOntime == null && formattedT3 == null) {
                return null
            }
            else {
                return `<span class="punctuality-text"><span class="percentage">${formattedOntime}%</span> On-Time | <span class="percentage">${formattedT3}%</span> T-3</span>`
            }
        },
        hasTopGap(toc) {
            // checks whether toc above in table has dropdown open
            // (this is needed so the element knows whether to round it's top borders or not)
            let previous_toc = null
            for (let row of this.operatorImpactData) {
                if (toc === row.toc) {
                    if (this.dropdownOpen[previous_toc]) {
                        return true
                    }
                    else {
                        return false
                    }
                }
                previous_toc = row.toc
            }
            return false

        },
        getOperatorData() {
            this.operatorLoading = true
            let loading_count = 0
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/individual/operator-breakdown`,
                method: "GET",
                params: {reference: this.referenceNumber},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
            .then(response => {
                this.operatorBreakdownData = response.data
                loading_count += 1
                if (loading_count >= 2) {this.operatorLoading = false}
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/individual/operator-impact`,
                method: "GET",
                params: {reference: this.referenceNumber},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
            .then(response => {
                this.operatorImpactData = response.data
                this.createDropdownOpenObject()
                loading_count += 1
                if (loading_count >= 2) {this.operatorLoading = false}
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        },
        getGraphData(serviceRow) {

            this.graphLoading = true
            if (this.activeService == null) {
                this.graphDiv = null
                this.graphLoading = false
            }
            else {
                 // create blob name
                let reference_number_string = this.referenceNumber.replaceAll("/", "-")
                let toc_id_string = String(serviceRow.toc_id).padStart(2, "0")
                let route_string = `[${String(serviceRow.route).replaceAll(",", ", ").replaceAll(/to(?!\s)/g, " to ")}]`
                let stopping_pattern_string = `[${String(serviceRow.trust_section_stopping_pattern_concat).replaceAll(",", ", ")
                    .replaceAll(/to(?=(?:stop)|(?:pass))/g, " to ")
                }]`
                let ref_blob_name = `${reference_number_string}-${toc_id_string}-${route_string}-${stopping_pattern_string}`
                axios({
                    url: `${process.env.VUE_APP_API_SERVER}/individual/speed-restriction-plot`,
                    method: "GET",
                    params: {reference_blob_name: ref_blob_name},
                    headers: {"accept": "application/json",
                            "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                    }
                })
                .then(response => {
                    this.graphDiv = response.data
                    this.graphLoading = false
                })
                .catch( error => {
                    console.log(error)
                    if (error.response) {
                        console.log(error.response.status)
                    }
                })
            }
        },
        getData() {
            this.getOperatorData()
        },
    },
    created() {
        this.getData()
    }
}
</script>

<style scoped>
.body {
    display: flex;
    flex-direction: row;
    height: 100%;
}
.table {
    flex-basis: 0;
    flex-grow: 1;
    padding: 1rem 1rem 1rem 0;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
}
#table-explanation {
    margin-bottom: 0;
    margin-top: 0;
}
.toc-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: solid;
    border-width: 1px;
    border-color: var(--colour-2);
    padding: 1rem 0.5rem 1rem 0.5rem;
}
.toc-data:hover, .service-row:hover, .dropdown-button:hover {
    cursor: pointer;
    background-color: #DBDDE6;
}
.service-row.active {
    background-color: #DBDDE6;
    border-color: #192460;
    border-top-width: 1px;
}
.service-row {
    border: solid;
    border-width: 1px;
    border-color: var(--colour-2);
    border-top-width: 0;
    padding: 1rem 0.5rem 1rem 0.5rem;
    font-size: 0.95rem;
    color: var(--colour-1);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.service-row .train-info {
    font-weight: 300;
}
.service-row .radio {
    font-size: 0.7rem;
}
.service-row:last-child {
    margin-bottom: 0.8rem;
}
.service-row > i {
    margin-right: 1.1rem;
}
.service-impact {
    display: inline-block;
    width: 7rem;
    margin-left: 1.5rem;
}
/* Rounding borders of table --------------------------------------------- */
    .toc-row:first-of-type .toc-data, .toc-data.has-top-gap {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
    }
    .toc-row:last-child .toc-data:not(.open), .service-row:last-child {
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
    }
/* ----------------------------------------------------------------------- */
.toc-data .left, .toc-data .right {
    display: flex;
    align-items: center;
}
.toc-name, .toc-impact {
    font-size: 1.1rem;
    color: var(--colour-1);
}
.toc-name {
    font-weight: bold;
    text-align: center;
}
.toc-data i {
    font-size: 0.3rem;
    color: var(--colour-1);
    padding-top: 0.2rem;
    margin: 0 0.5rem 0 0.5rem;
}
:deep(.punctuality-text) {
    font-size: 1.1rem;
    color: var(--colour-1);
}
:deep(.percentage) {
    font-size: 1.1rem;
    color: var(--colour-7);
    font-weight: bold;
}
.dropdown-button {
    background-color: transparent;
    border: 0;
    outline: 0;
}
.dropdown-button i {
    font-size: 1.5rem;
}
.graphs {
    flex-basis: 0;
    flex-grow: 1.5;
    display:flex;
    flex-direction: column;
    margin-top: 0.75rem;
}
/* Need this to make height of plot responsive. for some reason when 100%, it just defaults to 450px */
:deep(.js-plotly-plot) {
    height: 24.5rem !important;
}
.graph-title {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 1rem 0.5rem 3rem;
}
.spacer {
    height: 100%;
    background-color: var(--colour-2);
    width: 2px;
    margin: 0 0.5rem 0 0.5rem;
}
.graph-title .header {
    font-weight: 500;
    font-size: 1rem;
    padding: 0.25rem 0 0.25rem 0;
    white-space: nowrap;
}
.graph-title .header.overflow {
    text-overflow: ellipsis;
    max-width: 12.5rem;
    overflow: hidden;
}
.graph-title .text {
    font-weight: 300;
    font-size: 0.9rem;
}
</style>
