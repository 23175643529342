<!-- Displays content for the Overview Page -->

<template>
    <div id="overview-content">
        <Scorecard id="scorecard" />
        <div class="widecards">
            <DelayTrend class="wide-card" />
            <WorstSpeedRestrictions class="wide-card" @select-speed-restriction="onSelectSpeedRestriction" />
        </div>
        <RouteRegionMap ref="routeRegionMap" />
    </div>
</template>

<script>
import DelayTrend from "./DelayTrend.vue"
import RouteRegionMap from "./RouteRegionMap.vue"
import Scorecard from "./Scorecard.vue"
import WorstSpeedRestrictions from "./WorstSpeedRestrictions.vue"

export default {
    name: "OverviewContent",
    components: {
        DelayTrend,
        RouteRegionMap,
        Scorecard,
        WorstSpeedRestrictions,
    },
    methods: {
        onSelectSpeedRestriction(reference) {
            this.$refs.routeRegionMap.navigateToReference(reference);
        }
    },
    created() {
        this.$store.commit("setActiveHomePageTab", "OverviewContent")
    }
}
</script>

<style scoped>
#overview-content {
    display: flex;
    height: 100%;
}

#scorecard {
    flex-grow: 1;
    flex-basis: 0;
}

.widecards {
    display: flex;
    flex-direction: column;
    flex-grow: 3.5;
    flex-basis: 0;
}

.wide-card {
    flex-grow: 1;
    flex-basis: 0;
    height: 0;
}
</style>
