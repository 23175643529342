<!-- Displays content for the Route Operator page  -->

<template>
    <SelectedContent :view="activeType" :selected="selected"/>
</template>

<script>
import SelectedContent from './SelectedContent.vue'

export default {
    name: 'RouteOperatorPage',
    components: {
        SelectedContent
    },
    computed: {
        activeType() {
            return this.$store.state.activeType
        },
        selected() {
            let selected;
            if (this.activeType === 'route') {
                selected = this.$store.state.activeRoute;
            }
            else if (this.activeType === 'toc') {
                selected = this.$store.state.activeToc;
            }
            else if (this.activeType === 'region') {
                selected = this.$store.state.activeRegion;
            }
            return selected;
        }
    },
    watch: {
        "$route.params": function(params) {
            if (params.view) {
                this.setView(params.view, params.selected)
            }
        }
    },
    methods: {
        /**
         * Sets the 'view' and 'selected' parameters for the selectedContent
         * @param {String} view - the view of the page (can be 'route', 'toc'
         * or 'region')
         * @param {String} selected - the selected value of the page (such as
         * the name of route, toc, or region)
         */
        setView(view, selected) {
            if (view.toLowerCase() === "route") {
                this.$store.commit("setActiveType", "route")
                if (selected) {
                    this.$store.commit("setActiveRoute", selected)
                }
                else {
                    this.$router.replace(`/home/route_operator/route/${this.$store.state.activeRoute}`)
                }
            }
            else if (view.toLowerCase() === "toc") {
                this.$store.commit("setActiveType", "toc")
                if (selected) {
                    this.$store.commit("setActiveToc", selected)
                }
                else {
                    this.$router.replace(`/home/route_operator/toc/${this.$store.state.activeToc}`)
                }
            }
            else if (view.toLowerCase() === "region") {
                this.$store.commit("setActiveType", "region")
                if (selected) {
                    this.$store.commit("setActiveRegion", selected)
                }
                else {
                    this.$router.replace(`/home/route_operator/region/${this.$store.state.activeRegion}`)
                }
            }
        }
    },
    created() {

        this.$store.commit("setActiveHomePageTab", "RouteOperatorPage")

        const view = this.$route.params.view
        const selected = this.$route.params.selected
        this.setView(view, selected)
    }
}
</script>
