import { createApp } from 'vue'
import App from './App.vue'
// Imports for Primevue
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
// Import vue router
import {generateRouter} from './router.js'
// Import vuex storage
import { store } from './vuexstore/vuexStore.js'
// Import styles
import './style/global.css'
import './style/primevue_overwrite.css'
// Import custom
import './style/custom_icons/custom-icons.css'
// Bootstrap icons
import 'bootstrap-icons/font/bootstrap-icons.css';
// Tooltip
import Tooltip from 'primevue/tooltip';

// Create router
const router = generateRouter(store)

// Create app and mount
const app = createApp(App);
app.use(PrimeVue);
app.use(router);
app.use(store);
app.directive('tooltip', Tooltip);
app.mount('#app');