<!-- HomePage of the app -->

<template>
  <div>
    <HeaderBar />
    <div id="page-content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import HeaderBar from './headerbar/HeaderBar.vue'

export default {
  name: 'HomePage',
  components: {
    HeaderBar
  }
}
</script>

<style scoped>
#page-content {
  /* Top padding accounts for header bar (5rem) */
  padding: 5.5rem 0.5vw 1rem 0.5vw;
  height: 100vh;
}
</style>
