<template>
    <div id="analysis-content">
        <div class="top-cards">
            <OperatorBreakdown id="operator-breakdown"/>
        </div>
    </div>
</template>

<script>
import OperatorBreakdown from './OperatorBreakdown.vue'

export default {
    name: "Analysis",
    components: {
        OperatorBreakdown
    }
}
</script>

<style scoped>
#analysis-content {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.top-cards, .bottom-cards {
    flex-basis: 0;
    height: 0; /* fixes vertical scroll bars in card bodies */
    flex-grow: 1;
    display: flex;
}

#operator-breakdown {
    margin: 0;
    flex-basis: 0;
    flex-grow: 1.5;
}


</style>
