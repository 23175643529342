<!-- Displays the Delay Trend card -->

<template>
    <Card :expandable="false">
        <template #header-left>
            Delay trend <span class="pipe">|</span> {{ activeTitle }}
        </template>
        <template #header-right>
            <span class="kpis">
                <span class="tsr-time-loss">
                    <img src="@/assets/history.svg">
                    <span>
                        <div v-if="timeLossKpiLoading">
                            LOADING
                        </div>
                        <div v-else class="kpi-values">
                            {{formatImpact(kpiData.timeloss.tsr_total_daily_impact_minutes)}}
                        </div>
                        <div>
                            TSR time loss per day  
                        </div>
                    </span>
                </span>
                <span class="tsr-active-srs">
                    <img src="@/assets/quantity.svg">
                    <span>
                        <div v-if="activeSrsKpiLoading">
                            LOADING
                        </div>
                        <div v-else class="kpi-values">
                            {{ kpiData.speedrestrictions.tsr_current_speed_restriction_count }} restrictions
                        </div>
                        <div>
                            TSRs active today
                        </div>
                    </span>
                </span>
                <span v-if="this.$store.state.routesUsingEsrTool.includes(activeTitle)" class="esr-time-loss">
                    <img src="@/assets/history.svg">
                    <span>
                        <div v-if="timeLossKpiLoading">
                            LOADING
                        </div>
                        <div v-else class="kpi-values">
                            {{formatImpact(kpiData.timeloss.esr_total_daily_impact_minutes)}}
                        </div>
                        <div>
                            ESR time loss per day  
                        </div>
                    </span>
                </span>
                <span v-if="this.$store.state.routesUsingEsrTool.includes(activeTitle)" class="esr-active-srs">
                    <img src="@/assets/quantity.svg">
                    <span>
                        <div v-if="activeSrsKpiLoading">
                            LOADING
                        </div>
                        <div v-else class="kpi-values">
                            {{ kpiData.speedrestrictions.esr_current_speed_restriction_count }} restrictions
                        </div>
                        <div>
                            ESRs active today  
                        </div>
                    </span>
                </span>                
            </span>
        </template>
        <template #body>
            <!--
            <div v-show="loading">
            LOADING
            </div>
            -->
            <div>
                <div class="graph-title">
                    Time loss due to speed restrictions (7 day moving average, TSRs only)
                </div>
            </div>
            <div class="graph-container">
                <div class="graph-yaxis">Impact (minutes)</div>
                <div id="time-loss-graph"/>
            </div>
        </template>
    </Card>
</template>

<script>
import Card from "../common/Card.vue"
import Plotly from 'plotly.js-dist';
import axios from "axios";

export default {
    name: "DelayTrend",
    components: {
        Card
    },
    data() {
        return {
            graphData: null,
            graphDataLoading: true,
            first_render: true,
            kpiData: {
                speedrestrictions: null,
                timeloss: null
            },
            timeLossKpiLoading: true,
            activeSrsKpiLoading: true,
        }
    },
    computed: {
        activeRegion() {
            return this.$store.state.activeRegion
        },
        activeRoute() {
            return this.$store.state.activeRoute
        },
        activeToc() {
            return this.$store.state.activeToc
        },
        activeType() {
            return this.$store.state.activeType
        },
        activeTitle() {
            if (this.activeType === "route") {
                return this.activeRoute
            } else if (this.activeType === "region") {
                return this.activeRegion
            } else {
                return this.activeToc
            }
        }
    },
    watch: {
        activeRegion: function() {
            if (this.activeType === "region") {
                this.getRegionData()
            }
        },
        activeRoute: function() {
            if (this.activeType === "route") {
                this.getRouteData()
            }
        },
        activeToc: function() {
            if (this.activeType === "toc") {
                this.getTocData()
            }
        },
        activeType: function() {
            if (this.activeType === "route") {
                this.getRouteData()
            } else if (this.activeType === "region") {
                this.getRegionData()
            } else {
                this.getTocData()
            }
        }
    },
    methods: {
        formatImpact(impactNum) {
            if (impactNum == null) {
                return "No Data Available"
            }
            impactNum = impactNum.toLocaleString("en-UK")
            if (impactNum == 1) {
                impactNum += " minute"
            }
            else {
                impactNum += " minutes"
            }
            return impactNum
        },
        plotTimeSeries() {
            let rem = parseFloat(getComputedStyle(document.documentElement).fontSize)
            let dates = []
            let impacts = []
            // Add a filter which plots either tsr_daily_impact_minutes or esr_daily_impact_minutes depending on whether ESR or tsr is active
            /*
            if esrTsrFilter = 'ESR' {
                impact_data = tsr_daily_impact_minutes
            }
            else if esrTsrFilter = 'TSR' {
                impact_data = esr_daily_impact_minutes
            }
            else impact_data = summed_daily_impact_minutes 
            */
           
            /*
            // Plot TSR daily impact mins for national but total daily impact minutes for all other routes.
            if (this.activeTitle == 'National') {
                for (let row of this.graphData) {
                    impacts.push(row.tsr_daily_impact_minutes)
                    dates.push(row.date)
                }
            }
            else {
                for (let row of this.graphData) {
                    impacts.push(row.daily_impact_minutes)
                    dates.push(row.date)
                }
            }
            */

            // Plot TSR daily impact minutes for all 
            for (let row of this.graphData) {
                impacts.push(row.tsr_daily_impact_minutes)
                dates.push(row.date)
            }
            
            let data = [
                {
                    x: dates,
                    y: impacts,
                    line: {
                        shape: 'spline',
                        color: '#3153CB',
                        width: 3,
                    },
                    type: 'scatter',
                    mode: "lines",
                    connectgaps: true,
                    fillcolor: '#C1CBEF',
                    fill: "tozeroy",
                }
            ];
            let layout = {
                font: {
                    family: "Inter",
                    size: 12,
                    color: "#424552"
                },
                margin: {
                    l: 5 * rem,
                    t: 20,
                    b: 2*rem,
                },
                yaxis: {
                    automargin:true,
                    showline:true,
                    linecolor:'gray',
                    linewidth:0.5,
                    tickwidth:1,
                    ticklen:10,
                    tickfont:{
                        size:10
                    },
                    showgrid: true,
                },
                xaxis: {
                    margin:5*rem,
                    automargin:true,
                    showgrid: true,
                    tickwidth:1,
                    ticklen:10,
                    tickfont:{
                        size:10
                    }
                },
            }

            let config = {
                responsive: true,
                displaylogo: false
            }
            Plotly.react("time-loss-graph",data, layout, config)
        },
        getRouteData() {
            this.getRouteGraphData()
            this.getRouteKpiData()
        },
        getRouteGraphData() {
            this.graphDataLoading = true
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/overview/route-delay-trend`,
                method: "GET",
                params: {route: this.$store.state.activeRoute},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
            .then(response => {
                this.graphData = response.data
                this.graphDataLoading = false
                this.plotTimeSeries()
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        },
        getRouteKpiData() {
            this.timeLossKpiLoading = true
            this.activeSrsKpiLoading = true
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/route/kpi-active-speed-restrictions`,
                method: "GET",
                params: {route: this.$store.state.activeRoute},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
            .then(response => {
                this.kpiData.speedrestrictions = response.data
                this.activeSrsKpiLoading = false
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })

            axios({
                url: `${process.env.VUE_APP_API_SERVER}/route/kpi-total-time-loss-per-day`,
                method: "GET",
                params: {route: this.$store.state.activeRoute},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
            .then(response => {
                this.kpiData.timeloss = response.data
                this.timeLossKpiLoading = false
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        },
        getRegionData() {
            this.getRegionGraphData()
            this.getRegionKpiData()
        },
        getRegionGraphData() {
            this.graphDataLoading = true
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/overview/region-delay-trend`,
                method: "GET",
                params: {region: this.$store.state.activeRegion},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
            .then(response => {
                this.graphData = response.data
                this.graphDataLoading = false
                this.plotTimeSeries()
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        },
        getRegionKpiData() {
            this.timeLossKpiLoading = true
            this.activeSrsKpiLoading = true
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/region/kpi-active-speed-restrictions`,
                method: "GET",
                params: {region: this.$store.state.activeRegion},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
            .then(response => {
                this.kpiData.speedrestrictions = response.data
                this.activeSrsKpiLoading = false
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })

            axios({
                url: `${process.env.VUE_APP_API_SERVER}/region/kpi-total-time-loss-per-day`,
                method: "GET",
                params: {region: this.$store.state.activeRegion},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
            .then(response => {
                this.kpiData.timeloss = response.data
                this.timeLossKpiLoading = false
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        },
        getTocData() {
            this.getTocGraphData()
            this.getTocKpiData()
        },
        getTocGraphData() {
            this.graphDataLoading = true
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/overview/toc-delay-trend`,
                method: "GET",
                params: {toc: this.$store.state.activeToc},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
            .then(response => {
                this.graphData = response.data
                this.graphDataLoading = false
                this.plotTimeSeries()
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        },
        getTocKpiData() {
            this.timeLossKpiLoading = true
            this.activeSrsKpiLoading = true
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/toc/kpi-active-speed-restrictions`,
                method: "GET",
                params: {toc: this.$store.state.activeToc},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
            .then(response => {
                this.kpiData.speedrestrictions = response.data
                this.activeSrsKpiLoading = false
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })

            axios({
                url: `${process.env.VUE_APP_API_SERVER}/toc/kpi-total-time-loss-per-day`,
                method: "GET",
                params: {toc: this.$store.state.activeToc},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
            .then(response => {
                this.kpiData.timeloss = response.data
                this.timeLossKpiLoading = false
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        }
    },
    mounted() {
        if (this.activeType === "route") {
            this.getRouteData()
        } else if (this.activeType === "region") {
            this.getRegionData()
        } else {
            this.getTocData()
        }
    }
}
</script>

<style scoped>
#time-loss-graph {
    max-height: 25vh; 
    margin-left:2rem;
}

.graph-container{
    max-height: 25vh;
    margin:auto;

}
.graph-title {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    font-size: 1.1rem;
    font-weight: bold;
}

.graph-yaxis{
    transform: rotate(-90deg);
    height:10rem;
    float:left;
    margin-left:2rem;
    margin-top:2rem;
}
.pipe {
    font-weight: normal;
}

/* KPIS */
.kpis, .tsr-time-loss, .esr-time-loss, .tsr-active-srs, .esr-active-srs {
    display: flex;
    align-items: center;
    
}
.kpi-values {
    font-weight: bold;
    font-size: 1.2rem;
    
}
.kpis img {
    margin-right: 0.75rem;
    margin-left: 1.25rem;
    width: 2.5rem;
    height: 2.5rem;
    
}
.kpis > .active-srs {
    margin-left: 2rem;
    
}
</style>
