

<template>
    <Card id="attachments-card" :expandable="false">
        <template #header-left>
            Attachments
        </template>
        <template #body>
            <label for="file-upload" v-if="filesMetadata && filesMetadata.length < 3">
                <span class="file-upload"><i class="bi bi-upload"/> Upload attachments</span>
                <input id="file-upload" type="file" accept=".pdf" @change="uploadAttachment" hidden>
            </label>
            <span v-else class="file-upload" @click="fileLimitModal = true"><i class="bi bi-upload"/> Upload attachments</span>

            <div v-if="loadingFilesMetadata">loading</div>
            <div v-else-if="filesMetadata.length === 0">No attachments</div>
            <div v-else class="scroll-content">
                <div v-for="fileMetadata in filesMetadata" :key="fileMetadata.blobname" class="file-row">
                    <button class="download-button" @click="downloadAttachment(fileMetadata)"
                         :title="fileMetadata.filename"
                    >
                        <i class="bi bi-file-earmark-pdf-fill pdf-icon"/>
                        <span class="filename">{{fileMetadata.filename}}</span>
                    </button>
                    <button class="delete-button" @click="openDeleteAttachmentModal(fileMetadata)">
                        <i class="bi bi-trash-fill"/>
                    </button>
                </div>
            </div>
        </template>
    </Card>
    <!-- Delete File Confirmation Modal -->
    <div v-if="deleteFileModal" class="modal-mask" @click.self="deleteFileModal = false">
        <Card class="delete-modal-container" :expandable="false">
            <template #header-left>
                Delete '{{toDeleteMetadata.filename}}'?
            </template>
            <template #body>
                <div class="modal-buttons">
                    <Button @click.self="deleteFileModal = false">Cancel</Button>
                    <Button @click="deleteAttachment(toDeleteMetadata)">Delete</Button>
                </div>
            </template>
        </Card>
    </div>
    <!-- File limit alert Modal -->
    <div v-if="fileLimitModal" class="modal-mask" @click.self="fileLimitModal = false">
        <Card class="limit-modal-container" :expandable="false">
            <template #header-left>
                Can't upload more than three attachments per speed restriction
            </template>
            <template #body>
                <div class="modal-buttons">
                    <Button @click.self="fileLimitModal = false">Ok</Button>
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import Card from "../../common/Card.vue";
import Button from "../../common/Button.vue"
import axios from "axios";

export default {
    name: "TsrAttachments",
    components: {
        Card,
        Button
    },
    data() {
        return {
            filesMetadata: null,
            loadingFilesMetadata: true,
            fileLimitModal: false,
            deleteFileModal: false,
            toDeleteMetadata: null
        }
    },
    computed: {
        referenceNumber() {
            let ref = this.$store.state.activeIndividualSpeedRestriction
            return ref
        },
        referenceBlobName() {
            return this.referenceNumber.replaceAll("/", "-")
        }
    },
    watch: {
        referenceNumber: function() {
            this.getAttachmentsList()
        }
    },
    methods: {
        getAttachmentsList() {
            this.loadingFilesMetadata = true
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/individual/get-tsr-attachments`,
                method: "GET",
                params: {reference_blob_name: this.referenceBlobName},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
            .then(response => {
                this.filesMetadata= response.data
                this.loadingFilesMetadata = false
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        },
        uploadAttachment(event) {

            // check if 3 file limit has been reached
            if (this.filesMetadata.length >= 3) {
                this.fileLimitModal = true
                return
            }

            const file = event.target.files[0]
            const filename = file.name
            let fileReader = new FileReader()
            fileReader.onload = (event) => {
                this.uploadAttachmentToServer(event.target.result, filename)
            }
            fileReader.readAsDataURL(file);
        },
        uploadAttachmentToServer(filedata, filename) {
            // create data model
            const dataModel = {
                filedata: filedata,
                filename: filename
            }
            // send post request
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/individual/upload-tsr-attachment`,
                method: "POST",
                data: dataModel,
                params: {reference_blob_name: this.referenceBlobName},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`,
                        "Content-Type": "application/json"
                }
            })
            .then(() => {
                this.getAttachmentsList()
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        },
        downloadAttachment(fileMetadata) {
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/individual/download-tsr-attachment`,
                method: "GET",
                params: {blob_name: fileMetadata.blobname},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
            .then(response => {
                this.saveDataToFile(response.data, fileMetadata.filename)
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        },
        saveDataToFile(dataUrl, filename) {
            const blob = this.dataURItoBlob(dataUrl);
            const url = window.URL.createObjectURL(blob);
            let downloadElement = document.createElement("a");
            downloadElement.setAttribute("download", filename);
            downloadElement.setAttribute(
                "href",
                url
            );
            document.body.appendChild(downloadElement); // for it to work in firefox
            downloadElement.click();
            downloadElement.remove();
            window.URL.revokeObjectURL(url);
        },
        dataURItoBlob(dataURI) {
            /*
                Decodes base64 data from a URI and writes to a blob
            */

            // get data and mimeString from dataURI
            const split = dataURI.split(',')
            const data = split[1]
            const mimeString = split[0].split(':')[1].split(';')[0]

            // convert base64 to binary
            let byteString = atob(data);

            // write bytes to an ArrayBuffer
            let _arrayBuffer = new ArrayBuffer(byteString.length);

            // create a view into the buffer
            let ia = new Uint8Array(_arrayBuffer);

            // set the bytes of the buffer to the correct values
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            // write ArrayBuffer to blob
            const blob = new Blob([_arrayBuffer], {type: mimeString});
            return blob;
        },
        openDeleteAttachmentModal(fileMetaData) {
            this.toDeleteMetadata = fileMetaData
            this.deleteFileModal = true
        },
        deleteAttachment(fileMetadata) {
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/individual/delete-tsr-attachment`,
                method: "DELETE",
                params: {blob_name: fileMetadata.blobname},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
            .then(() => {
                this.deleteFileModal = false
                this.getAttachmentsList()
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        }
    },
    created() {
        this.getAttachmentsList()
    }
}
</script>

<style scoped>
#attachments-card {
    margin: 0;
    padding-top: 0.5rem;
    height: 0;
    flex-basis: 0;
    flex-grow: 1;
}
.scroll-content {
    height: calc(100% - 2.2rem);
    overflow: auto;
    overflow-x: hidden;
    padding-right: 1.5rem;
}
.file-upload{
    margin: 0.1rem 0 0.6rem 0;
    display: inline-block;
    height: 1.5rem;
    font-weight: bold;
    color: var(--colour-1);
    cursor: pointer;
}
.file-upload > i {
    font-size: 1.1rem;
    margin-right: 0.5rem;
}
/* 
    Styling file rows
*/
.file-row {
    display: flex;
    justify-content: space-between;
    margin: 0.1rem 0 0.1rem 0;
    align-items: center;
}
.file-row button {
    background: transparent;
    border: 0;
    outline: 0;
    padding: 0;
    font-size: 1rem;
}
.file-row .download-button{
    color: var(--colour-6);
    cursor: pointer;
    display: flex;
    align-items: center;
}
.file-row .download-button > .pdf-icon {
    color: var(--colour-1);
    font-size: 1.1rem;
    margin-right: 0.25rem;
}
.file-row .download-button > .filename {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    width: 13rem;
}
.file-row .download-button:hover {
    font-weight: bold;
}
.file-row .delete-button {
    cursor: pointer;
    color: var(--colour-7);
    font-size: 1.1rem;
}
/* 
    Modal Dialogues
*/
.modal-mask {
position: fixed;
z-index: 9998;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
justify-content: center;
align-items: center;
}
.limit-modal-container {
/* width: 35vw; */
height: 15vh;
}
.delete-modal-container {
/* width: 20vw; */
height: 15vh;
}
.modal-container :deep(.header .left) {
    display: flex;
    justify-content: center;
}
.modal-buttons {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
}
.modal-buttons :deep(button) {
    font-size: 1.25rem !important;
    margin: 0 0.75rem 0 0.75rem;
}
</style>