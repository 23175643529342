<template>
  <div id="management-content">
    <CountByNRPeriods />
  </div>
</template>

<script>
import CountByNRPeriods from "./CountByNRPeriods.vue"

export default {
  name: "ManagementContent",
  components: {
    CountByNRPeriods,
  },
  created() {
    this.$store.commit("setActiveHomePageTab", "ManagementContent");
  }
};
</script>

<style scoped>
#management-content {
  height: 100%;
  display: flex;
}
</style>
