<template>

    <div id="user-input-content">
        <div class="left-cards">
            <div v-if="userInputResponseData == null" class="add-user-input" @click="addUserInputModal = true">
                <div class="content">
                    Add Estimated Removal Date
                </div>
                <i class="sprint-plus-circle"/>
            </div>
            <div v-else>
                <div @click="editUserInputModal = true" class = "edit-user-input">
                    <div class="content">
                        Edit Estimated Removal Date
                    </div>
                </div>
                <div @click="deleteUserInputModal = true" class="delete-user-input">
                    <div class="content">
                        Delete Estimated Removal Date
                    </div>
                </div>                
            </div>
        </div>
        <Card id="user-inputs">
            <template #header-left>
                User Input Data
            </template>
            <template #body>
                <div v-if="loadingUserInputResponseData">
                    LOADING
                </div>
                <div v-else-if="userInputResponseData == null"> 
                    <div class="no-user-input-text"> No user input content </div>
                </div>
                <div v-else class="user-input-content">
                    <DataTable :value="userInputData" :autolayout="true">
                        <Column field="user_input" header="Information">
                        </Column>
                        <Column field="value" header="Value">
                        </Column>
                    </DataTable>           
                </div>
            </template>
        </Card>
        <!-- Add User Input Modal -->
        <div v-if="addUserInputModal" class="modal-mask" @click.self="addUserInputModal = false">
            <Card class="add-user-input-modal-container" :expandable="false">
                <template #header-left>
                    Add Estimated Removal Date
                </template>
                <template #body>
                    <div class="modal-card-body">
                        <div>
                            <div><b>Estimated Removal Date</b></div>
                            <label for="add-estimated-removal-date" hidden>Due date</label>
                            <input type="date" id="add-estimated-removal-date" name="estimated removal date" v-model="estimatedRemovalDate">
                        </div>
                        <div class="modal-card-buttons">
                        <Button @click="addUserInput">
                            Add Estimated Removal Date
                        </Button>
                        <Button @click="addUserInputModal = false">
                            Cancel
                        </Button>
                        </div>
                    </div>
                </template>
            </Card>
        </div>
        <!-- Edit User Input Modal -->
        <div v-if="editUserInputModal" class="modal-mask" @click.self="this.editUserInputModal = false">
            <Card class="add-user-input-modal-container" :expandable="false">
                <template #header-left>
                    Update Estimated Removal Date
                </template>
                <template #body>
                    <div class="modal-card-body">
                        <div>
                            <div><b>Estimated Removal Date</b></div>
                            <label for="edit-estimated-removal-date" hidden>Due date</label>
                            <input type="date" id="edit-estimated-removal-date" name="estimated removal date" v-model="estimatedRemovalDate">
                        </div>
                        <div class="modal-card-buttons ">
                        <Button @click="updateUserInput">
                            Update Estimated Removal Date
                        </Button>
                        <Button @click="editUserInputModal = false">
                            Cancel
                        </Button>
                        </div>
                    </div>
                </template>
            </Card>
        </div>
        <!-- Delete User Input Modal -->
        <div v-if="deleteUserInputModal" class="modal-mask" @click.self="this.deleteUserInputModal = false">
            <Card class="add-user-input-modal-container" :expandable="false">
                <template #header-left>
                    Delete Estimated Removal Date
                </template>
                <template #body>
                    <div class="modal-card-body">
                        <div>
                           Are you sure you want to delete user input data?
                        </div>
                        <div class="modal-card-buttons ">
                        <Button @click="deleteUserInput">
                            Delete Estimated Removal Date
                        </Button>
                        <Button @click="deleteUserInputModal = false">
                            Cancel
                        </Button>
                        </div>
                    </div>
                </template>
            </Card>
        </div>
    </div>
</template>

<script>
import Card from '../../common/Card.vue';
import Button from '../../common/Button.vue'
import DataTable from "primevue/datatable/sfc";
import Column from "primevue/column/sfc";
import format from "src/functions/formatter.js";
import axios from "axios";

export default {
    name: "UserInput",
    components: {
        Card,
        Button,
        DataTable,
        Column
    },
    data() {
        return {
            userInputResponseData: null,
            loadingUserInputResponseData: true,
            userInputData: [
                {user_input: "Estimated removal date", value: null}
            ],
            addUserInputModal: false,
            editUserInputModal: false,
            deleteUserInputModal: false,
            userInput: null,
            estimatedRemovalDate: null
        }
    },
    computed: {
        referenceNumber() {
            let ref = this.$store.state.activeIndividualSpeedRestriction
            return ref
        },
        currentDate() {
            return new Date()
        },
    },
    watch: {
        referenceNumber: function() {
            this.getData()
        }
    },
    methods: { 
        addUserInput() {
            // create user input model
            let userInputModel = {
                input_user: "Test",
                estimated_removal_date: this.estimatedRemovalDate,
                spate: false,
                prev_sr_code: "N/A",
                input_superseded: false
            }
            // send post request
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/user-input/create-user-input`,
                method: "POST",
                data: userInputModel,
                params: {reference: this.referenceNumber},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`,
                        "Content-Type": "application/json"
                }
            })
            .then(() => {
                this.getUserInputData()
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
            // modal to false
            this.addUserInputModal = false
        },
        updateUserInput() {
            // create action model
            let userInputModel = {
                input_user: "Test",
                estimated_removal_date: this.estimatedRemovalDate,
                spate: false,
                prev_sr_code: "N/A",
                input_superseded: false
            }
            // send patch request to mark prev user input as old
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/user-input/mark-prev-input-as-superseded`,
                method: "PATCH",
                data: userInputModel,
                params: {reference: this.referenceNumber,
                        id: this.userInputResponseData.id
                },
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`,
                        "Content-Type": "application/json"
                }
            })
            .then(() => {
                this.addUserInput()
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
            this.editUserInputModal = false
        },
        deleteUserInput() {
            // create action model
            let userInputModel = {
                input_user: "Test",
                estimated_removal_date: this.estimatedRemovalDate,
                spate: false,
                prev_sr_code: "N/A",
                input_superseded: false
            }
            // send patch request to mark prev user input as old
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/user-input/mark-prev-input-as-superseded`,
                method: "PATCH",
                data: userInputModel,
                params: {reference: this.referenceNumber,
                        id: this.userInputResponseData.id
                },
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`,
                        "Content-Type": "application/json"
                }
            })
            .then(() => {
                this.getUserInputData()
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
            this.deleteUserInputModal = false
        },
        // Get user input from database to display on page.
        getUserInputData() {
            this.loadingUserInputResponseData = true
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/user-input/get-user-input`,
                method: "GET",
                params: {reference: this.referenceNumber},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
            .then(response => {
                this.userInputResponseData = response.data
                this.estimatedRemovalDate = response.data.estimated_removal_date
                this.userInputData[0].value = format.formatDate(response.data.estimated_removal_date)
                this.loadingUserInputResponseData = false
            })
            .catch( error => {
                this.loadingUserInputResponseData = false
                this.userInputResponseData = null
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })

            // Emit event to update the active time KPI in the Indivdual SR page
            this.$emit("inputUpdated", this.referenceNumber)  
        },
        getData() {
            this.getUserInputData()
        }
    },
    created() {
        this.getData()
    }
}
</script>

<style scoped>
::v-deep .body {
    padding-right: 0;
}
.user-input-content {
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    padding-right: 1.5rem;
    z-index: 2;
}
#user-input-content {
    height: 100%;
    display: flex;
}
.left-cards {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    margin-right: 0.75rem;
}
.add-user-input, .edit-user-input, .delete-user-input, .open-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem 1.5rem 0.3rem 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.add-user-input, .edit-user-input, .delete-user-input {
    background-color: var(--colour-12);
    margin-bottom: 1.5rem;
    color: var(--colour-4);
    font-size: 1.6rem;
    font-weight: bold;
}
.delete-user-input {
    background-color: var(--colour-7);
}
.add-user-input:hover, .edit-user-input:hover,.delete-user-input:hover {
    cursor: pointer;
}
.add-user-input i, .edit-user-input i {
    color: var(--colour-4);
}
i {
    font-size: 3.5rem;
}

.delete-user-input i {
    color: var(--colour-1);
}
i {
    font-size: 3.5rem;
}

#user-inputs {
    flex-grow: 4;
    flex-basis: 0;
    margin: 0 0 0 0.75rem;
}
::v-deep #user-inputs .p-datatable-thead th {
    font-size: 1.3rem !important;
}
::v-deep #user-inputs .p-datatable-tbody td{
    font-size: 1.3rem;
}
.no-user-input-text {
    font-size: 1.25rem;
    margin-top: 2rem;
    text-align: center;
}
.input-buttons {
    font-size: 1.5rem;
    position: fixed;
    bottom: 8%
}


/* User Input modals */
.modal-mask {
position: fixed;
z-index: 9998;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
justify-content: center;
align-items: center;
}
.add-user-input-modal-container, .edit-action-modal-container, .delete-action-modal-container, .complete-action-modal-container {
width: 30vw;
height: 40vh;
}
.modal-card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}
.modal-card-body input:not([type='checkbox']) {
    width: calc(100% - 1.5rem);
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    margin-right: 1.5rem;
    font-family: "Inter";
    font-size: 1rem;
    color: var(--colour-6);
    border-radius: 0.5rem;
    border-style: solid;
    border-color: var(--colour-2);
    outline: none;
}
.modal-card-body:not(.delete, .complete) input:not([type='checkbox']):hover {
    border-color: var(--colour-3);
}
.modal-card-body:not(.delete, .complete) input:not([type='checkbox']):focus {
    border-color: var(--colour-1);
}
.modal-card-body input[type='checkbox'] {
    transform: scale(1.25);
    margin-left: 1rem;
}
.comment-line {
    display: flex;
    align-items: center;
}
.modal-card-buttons {
    display: flex;
    justify-content: center;
}
.modal-card-buttons :deep(button) {
    font-size: 1.25rem;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    margin: 0 0.75rem 0 0.75rem;
}

</style>
