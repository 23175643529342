<!-- Common vue component used for cards -->

<template>
    <div class="card">
        <div class="header">
            <span class="left">
                <slot name="header-left" />
            </span>
            <span class="central">
                <slot name="header-central" />
            </span>
            <span class="right">
                <slot name="header-right" />
            </span>
        </div>
        <div class="body" :style="bodyScrollable">
            <slot name="body" />
        </div>
        <div class="footer">
            <span class="left">
                <button v-if="expandable" @click="switchModal" class="modal-button"><i class="sprint-expand" /></button>
            </span>
            <span class="right">
                <slot name="footer-right" />
            </span>
        </div>
        <!-- MODAL -->
        <div v-if="modal" class="modal-mask" @click.self="modalOff">
            <div class="modal-container card" ref="modal">
                <div class="header">
                    <span class="left">
                        <slot name="header-left" />
                    </span>
                    <span class="right">
                        <slot name="header-right" />
                    </span>
                </div>
                <div class="body" :style="bodyScrollable">
                    <slot v-if="modalBodySlotGiven" name="modalBody" />
                    <slot v-else name="body" />
                </div>
                <div class="footer">
                    <span class="left">
                        <button @click="switchModal" class="modal-button"><i class="sprint-expand" /></button>
                    </span>
                    <span class="right">
                        <slot name="footer-right" />
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Card",
    data() {
        return {
            modal: false
        }
    },
    props: {
        scrollable: {
            default: "None",
            type: String
        },
        expandable: {
            default: true,
            type: Boolean
        }
    },
    computed: {
        bodyScrollable() {
            if (this.scrollable === "Vertical") {
                return "overflow: auto; overflow-x: hidden;"
            }
            else if (this.scrollable === "Horizontal") {
                return "overflow: auto; overflow-y: hidden;"
            }
            else if (this.scrollable === "Both") {
                return "overflow: auto;"
            }
            else {
                return "overflow: hidden;"
            }
        },
        modalBodySlotGiven() {
            return !!this.$slots.modalBody
        }
    },
    methods: {
        switchModal() {
            this.modal = !this.modal
        },
        modalOff() {
            this.modal = false
        }
    },
}
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-container {
    width: 90vw;
    height: 90vh;
}

.modal-button {
    margin: 0.2rem 0 0.2rem 0.2rem;
    background: 0;
    border: 0;
    outline: 0;
}

.modal-button:hover {
    cursor: pointer;
}

.card {
    background-color: var(--colour-4);
    margin: 0.25rem;
    padding: 1rem 0rem 0rem 0rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    color: var(--colour-6);

    display: flex;
    flex-direction: column;
}

/* Extra padding for body and header */
.body {
    padding: 0 1.5rem 0 1.5rem;
    flex-grow: 1;
}

.header>.left {
    font-weight: bold;
    font-size: 1.5rem;
}

.header>.right {
    font-size: 1.1rem;
}

.header>.central {
    font-weight: bold;
}

.header {
    display: flex;
    justify-content: space-between;
    border-bottom-style: solid;
    border-color: var(--colour-2);
    border-bottom-width: 1px;
    align-items: center;
    padding: 0 0.5rem 0.3rem 0.5rem;
    margin: 0 1rem 0 1rem;
}
</style>
