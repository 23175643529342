class SrTypeChecker{
    /**
     * Converts a value and unit to correct format for singular/plural
     * 
     * @param {String} type - the type of restriction (e.g. 'TSR' or 'ESR')
     * @returns {Boolean} - returns boolean depending on value of type
     **/
    static isEsr(type) {
        if(type == "ESR"){
            return true
        }
        return false
    }
}

export default SrTypeChecker