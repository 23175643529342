
<template>
    <button @click="downloadSpeedRestrictionsTable"><i class="pi pi-cloud-download"/></button>
</template>

<script>
import axios from "axios";

export default {
    name: "DownloadSpeedRestrictionsTableButton",
    props: ["filters"],
    computed: {
        apiAuthToken() {
            return this.$store.state.authenticator.token;
        },
        activePage() {
            // returns the active page. can be one of "route", "region", or "toc"

            return this.$store.state.activeType;
        },
        pageData() {
            // returns data related to the currently selected page
            //
            // returns an endpoint and parameters to download a register, and
            // also provides a filename for the resulting csv

            const server = process.env.VUE_APP_API_SERVER;
            const fileEnding = "SpeedRestrictionsRegister";
            const pageData = {
                route: {
                    endpoint: `${server}/route/current-speed-restrictions-register`,
                    filename: `${this.$store.state.activeRoute}_${fileEnding}`,
                    params: {route: this.$store.state.activeRoute},

                },
                region: {
                    endpoint: `${server}/region/current-region-sr-register`,
                    filename: `${this.$store.state.activeRegion}_${fileEnding}`,
                    params: {region: this.$store.state.activeRegion},
                },
                toc: {
                    endpoint: `${server}/toc/current-toc-sr-register`,
                    filename: `${this.$store.state.activeToc}_${fileEnding}`,
                    params: {toc: this.$store.state.activeToc},
                }
            }[this.activePage];

            return pageData;
        }
    },
    methods: {
        async downloadSpeedRestrictionsTable() {
            // downloads a register of speed restrictions to a csv

            // get the speed restrictions table
            let table = await this.getData();
            
            // apply filters
            table = this.filterRegister(table, this.filters)

            //convert to csv
            let csv = this.toCSV(table);

            // download the file
            // Create a Blob object
            const blob = new Blob([csv], { type: 'text/csv' });

            // Create an object URL
            const url = URL.createObjectURL(blob);

            // Download file
            this.download(url, `${this.pageData.filename}.csv`);

            // Release the object URL
            URL.revokeObjectURL(url);
        },
        getData() {
            // gets a speed restrition register in json format

            return axios({
                url: this.pageData.endpoint,
                method: "GET",
                params: this.pageData.params,
                headers: {"accept": "application/json",
                    "Authorization": `Bearer ${this.apiAuthToken}`
                }
            })
            .then(response => {
                return response.data;
        
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            });
        },
        toCSV(array) {
            // converts an array of objects into a csv string

            // get column headers
            let columns = Object.keys(array[0]);


            // create header
            let csvString = columns.join(",") + "\n";

            // add rows
            array.forEach(function(row){
                csvString += columns.map(column => `"${row[column]}"`).join(",") + "\n";
            });

            return csvString;
        },
        download(url, filename) {
            // prompts the user to download a file from a given URL

            // Create a new link
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = filename;

            // Append link to the DOM
            document.body.appendChild(anchor);

            // Trigger click event on download link
            anchor.click();

            // Remove element from DOM
            document.body.removeChild(anchor);
        },
        filterRegister(register, filters) {
            // filters a given speed restriction register

            // check if there are no filters and skip
            let has_filters = false;
            for (const column in filters) {
                if (filters[column].value.length > 0) {
                    has_filters = true;
                    break;
                }
            }
            if (!has_filters) {
                return register;
            }

            const filteredRegister = register.filter(row => {
                for (const column in filters) {
                    if (
                        filters[column].value.length > 0
                        && filters[column].value.indexOf(row[column]) === -1
                    ) {
                        return false;
                    }
                }
                return true;
            });
            return filteredRegister;
        },
    }
}
</script>

<style scoped>
button {
    background-color: transparent;
    border: 0;
    border-radius: 50%;
    padding: 0.4rem;
    text-align: center;
    margin-left: 2rem;
}
button:hover {
    cursor: pointer;
    background-color: var(--colour-2);
}
i {
    font-size: 2rem;
    color: var(--colour-6);
}
</style>
