import axios from "axios";

class SelectedContentHandler {
    
    static selectionClicked = {
        route: (route, router) => {
            router.push(`/home/route_operator/route/${route}`)
        },
        toc: (toc, router) => {
            router.push(`/home/route_operator/toc/${toc}`)
        },
        region: (region, router) => {
            router.push(`/home/route_operator/region/${region}`)
        }
    }

    static selectionList = {
        route: (authToken) => {
            return axios({
                url: `${process.env.VUE_APP_API_SERVER}/route/route-list`,
                method: "GET",
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${authToken}`
                }
            })
            .then(response => {
                let routes = response.data.map((element) => {
                    return element.route
                })
                routes.unshift("National")
                return routes
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        },
        toc: (authToken) => {
            return axios({
                url: `${process.env.VUE_APP_API_SERVER}/toc/toc-list`,
                method: "GET",
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${authToken}`
                }
            })
            .then(response => {
                return response.data.map((element) => {
                    return element.toc
                })
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        },
        region: (authToken) => {
            return axios({
                url: `${process.env.VUE_APP_API_SERVER}/region/region-list`,
                method: "GET",
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${authToken}`
                }
            })
            .then(response => {
                return response.data.map((element) => {
                    return element.region
                })
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        }
    }

    static registerData = {
        route: (activeRoute, authToken) => {
            return axios({
                url: `${process.env.VUE_APP_API_SERVER}/route/current-speed-restrictions-register`,
                method: "GET",
                params: {route: activeRoute},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${authToken}`
                }
            })
            .then(response => {
                return response.data
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        },
        toc: (activeToc, authToken) => {
            return axios({
                url: `${process.env.VUE_APP_API_SERVER}/toc/current-toc-sr-register`,
                method: "GET",
                params: {toc: activeToc},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${authToken}`
                }
            })
            .then(response => {
                return response.data
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        },
        region: (activeRegion, authToken) => {
            return axios({
                url: `${process.env.VUE_APP_API_SERVER}/region/current-region-sr-register`,
                method: "GET",
                params: {region: activeRegion},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${authToken}`
                }
            })
            .then(response => {
                return response.data
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        },
    }

    static barGraphPrimaryData = {
        route: (activeRoute, authToken) => {
            return axios({
                url: `${process.env.VUE_APP_API_SERVER}/route/operator-breakdown`,
                method: "GET",
                params: {route: activeRoute},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${authToken}`
                }
            })
            .then(response => {
                return response.data
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        },
        toc: (activeToc, authToken) => {
            return axios({
                url: `${process.env.VUE_APP_API_SERVER}/toc/route-breakdown`,
                method: "GET",
                params: {toc: activeToc},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${authToken}`
                }
            })
            .then(response => {
                return response.data
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        },
        region: (activeRegion, authToken) => {
            return axios({
                url: `${process.env.VUE_APP_API_SERVER}/region/operator-breakdown`,
                method: "GET",
                params: {region: activeRegion},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${authToken}`
                }
            })
            .then(response => {
                return response.data
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        },
    }

    static barGraphSecondaryData = {
        route: () => {
            return null
        },
        toc: (activeToc, authToken) => {
            return axios({
                url: `${process.env.VUE_APP_API_SERVER}/toc/delivery-unit-breakdown`,
                method: "GET",
                params: {toc: activeToc},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${authToken}`
                }
            })
            .then(response => {
                return response.data
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        },
        region: () => {
            return null
        }
    }

    static barGraphProps = {
        route: {
            primaryTitle: "Operator breakdown",
            primaryColumns: {label: 'toc', value: 'daily_impact_minutes', percentage: 'daily_impact_percentage'},
        },
        toc: {
            primaryTitle: "Route breakdown",
            secondaryTitle: "Delivery units",
            primaryColumns: {label: 'route', value: 'daily_impact_minutes', percentage: 'daily_impact_percentage'},
            secondaryColumns: {label: 'delivery_unit', value: 'daily_impact_minutes', percentage: 'daily_impact_percentage'}
        },
        region: {
            primaryTitle: "Operator breakdown",
            primaryColumns: {label: 'toc', value: 'daily_impact_minutes', percentage: 'daily_impact_percentage'},
        }
    }

    static treeMapChartData = {
        route: (activeRoute, authToken) => {
            return axios({
                url: `${process.env.VUE_APP_API_SERVER}/route/delivery-unit-breakdown`,
                method: "GET",
                params: {route: activeRoute},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${authToken}`
                }
            })
            .then(response => {
                return response.data
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        },
        toc: (activeToc, authToken) => {
            return axios({
                url: `${process.env.VUE_APP_API_SERVER}/toc/category-breakdown`,
                method: "GET",
                params: {toc: activeToc},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${authToken}`
                }
            })
            .then(response => {
                return response.data
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        },
        region: (activeRegion, authToken) => {
            return axios({
                url: `${process.env.VUE_APP_API_SERVER}/region/delivery-unit-breakdown`,
                method: "GET",
                params: {region: activeRegion},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${authToken}`
                }
            })
            .then(response => {
                return response.data
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        }
    }

    static treeMapChartProps = {
        route: {
            title: "Delivery units",
            categoryLabel: "delivery_unit"
        },
        toc: {
            title: "Breakdown by category",
            categoryLabel: "reason"
        },
        region: {
            title: "Delivery units",
            categoryLabel: "delivery_unit"
        },
    }

    static kpiSpeedRestrictionsCountData = {
        route: (activeRoute, authToken) => {
            return axios({
                url: `${process.env.VUE_APP_API_SERVER}/route/kpi-active-speed-restrictions`,
                method: "GET",
                params: {route: activeRoute},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${authToken}`
                }
            })
            .then(response => {
                return response.data
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        },
        toc: (activeToc, authToken) => {
            return axios({
                url: `${process.env.VUE_APP_API_SERVER}/toc/kpi-active-speed-restrictions`,
                method: "GET",
                params: {toc: activeToc},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${authToken}`
                }
            })
            .then(response => {
                return response.data
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        },
        region: (activeRegion, authToken) => {
            return axios({
                url: `${process.env.VUE_APP_API_SERVER}/region/kpi-active-speed-restrictions`,
                method: "GET",
                params: {region: activeRegion},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${authToken}`
                }
            })
            .then(response => {
                return response.data
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        },
    }

    static kpiTimeLossData = {
        route: (activeRoute, authToken) => {
            return axios({
                url: `${process.env.VUE_APP_API_SERVER}/route/kpi-total-time-loss-per-day`,
                method: "GET",
                params: {route: activeRoute},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${authToken}`
                }
            })
            .then(response => {
                return response.data
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        },
        toc: (activeToc, authToken) => {
            return axios({
                url: `${process.env.VUE_APP_API_SERVER}/toc/kpi-total-time-loss-per-day`,
                method: "GET",
                params: {toc: activeToc},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${authToken}`
                }
            })
            .then(response => {
                return response.data
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        },
        region: (activeRegion, authToken) => {
            return axios({
                url: `${process.env.VUE_APP_API_SERVER}/region/kpi-total-time-loss-per-day`,
                method: "GET",
                params: {region: activeRegion},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${authToken}`
                }
            })
            .then(response => {
                return response.data
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        }
    }
}

export default SelectedContentHandler