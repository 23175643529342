<!-- LoginPage of the app -->

<template>
    <div>
        <div id="header">
            <span id="logo">
                <img src="@/assets/logo_white.png"/>
                <span class="text">
                    <div class="title">S P R I N T</div>
                    <div class="under-title">Speed Restriction Intelligence</div>
                </span>
            </span>
        </div>
        <div id="title-element">
            <div id="sprint-title">
                S P R I N T
            </div>
            <div id="sprint-description">
                Speed Restriction Intelligence
            </div>
        </div>
        <form @submit.prevent="login">
            <div id="login-ui">
                <div id="inputs">
                    <div class="input-section">
                        <label>Username</label><br/>
                        <input required v-model="username" type="text"/>
                    </div>
                    <div class="input-section">
                        <label>Password</label><br/>
                        <input required v-model="password" type="password"/>
                    </div>
                    <!-- Error Message Options -->
                    <div class="input-section error-msg" v-if="login_response==401">
                        <i class="pi pi-exclamation-circle"></i>
                        Incorrect Email address or Password
                    </div>
                    <div class="input-section error-msg" v-if="login_response==0">
                        <i class="pi pi-exclamation-circle"></i>
                        Network Error
                    </div>
                </div>
                <button type="submit">Login</button>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    name: 'LoginPage',
    data() {
        return {
            username: null,
            password: null,
            login_response: null, // required for error message display
        }
    },
    methods: {
        async login() {
            // get token
            await this.$store.dispatch("authenticator/getToken", {username: this.username, password: this.password})
            
            // get response and reset to null in store
            const response_status = this.$store.state.authenticator.response_status_get_token
            this.login_response = response_status
            this.$store.commit("authenticator/setResponseStatusGetToken", null)
            
            // check if response ok and redirect to to home
            if (response_status==200) {
                this.$router.replace("/home")
            }
        }
    }
}
</script>

<style scoped>
#header {
    height: 5rem;
    width: 100%;
    background-color: var(--colour-1);
    color: var(--colour-4);
    display: flex;
    align-items: center;
    justify-content: left;
}

#header h1 {
    margin: 0;
}

#login-ui {
    margin-top: 4rem;
    padding: 1rem 1.5rem 1rem 1.5rem;
    background-color: var(--colour-4);
    border-radius: 1rem;
    width: 100%;
    max-width: 60rem;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 2px 6px rgba(63, 93, 126, 0.4);
}

.input-section:not(:first-child) {
    margin-top: 1rem;
}

label{
    color: var(--colour-6);
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    display: inline-block;
}

input {
    background-color: var(--colour-4);
    border: 1px;
    border-style: solid;
    border-color: var(--colour-6);
    border-radius: 0.8rem;
    padding: 0.5rem;
    color: var(--colour-6);
    width: 100%;
    font-size: 1rem;
    outline: none;
}

.error-msg {
    color: var(--colour-1);
}
.error-msg i {
    margin-right: 0.5rem;
}

button{
    margin-top: 1rem;
    background-color: var(--colour-8);
    color: var(--colour-4);
    font-size: 1.5rem;
    font-weight: bold;
    padding: 0.3rem 1.2rem 0.3rem 1.2rem;
    border-radius: 0.5rem;
    border-width: 0;
    box-shadow: 0px 2px 6px rgba(63, 93, 126, 0.4);
}

button:hover {
    cursor: pointer;
}

/*
    Logo Styling
*/

#logo {
    display: flex;
    flex-direction: row;
    left: 1rem;
    color: var(--colour-4);
    align-items: center;
    margin-left: 1rem;
}
#logo > img {
    height: 3.5rem;
    margin-right: 1rem;
}
#logo > .text > .title {
    font-weight: bold;
    font-size: 2rem;
}
#logo > .text > .under-title {
    font-size: 0.8rem;
    color: var(--colour-11);
}

/*
    Title styling
*/
#title-element {
    margin-top: 3rem;
    text-align: center;
    color: var(--colour-6);
}
#sprint-title {
    font-size: 3rem;
    font-weight: bold;
}
#sprint-description {
    font-size: 1rem;
}
</style>