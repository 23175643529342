<!-- 
    Operator Impact card on Overview tab of Individual Page
-->

<template>
<Card id="operator-impact-card">
    <template #header-left>
        Operator Impact
    </template>
    <template #body>
        <div v-if="loading.operatorImpact">
            LOADING
        </div>
        <div v-else class="scroll-content">
            <TocTable :data="operatorImpactData"/>
        </div>
    </template>
</Card>
</template>

<script>
import axios from 'axios';
import Card from '../../../common/Card.vue';
import TocTable from './TocTable.vue';

export default {
    name: "OperatorImpact",
    components: {
        Card,
        TocTable
    },
    data() {
        return {
            loading: {
                operatorImpact: true
            },
            operatorImpactData: null
        }
    },
    computed: {
        referenceNumber() {
            // reference number for currently selected speed restriction
            let ref = this.$store.state.activeIndividualSpeedRestriction
            return ref
        }
    },
    watch: {
        referenceNumber: function() {
            this.getOperatorImpactData()
        }
    },
    methods: {
        getOperatorImpactData() {
            this.loading.operatorImpact = true
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/individual/operator-impact`,
                method: "GET",
                params: {reference: this.referenceNumber},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
            .then(response => {
                this.operatorImpactData = response.data
                this.loading.operatorImpact = false
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        }
    },
    created() {
        this.getOperatorImpactData()
    },
}
</script>

<style scoped>
::v-deep .body {
    padding-right: 0;
}
:deep(.header .right) {
    display: inline-flex;
    align-items: center;
}
.scroll-content {
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    padding-right: 1.5rem;
}
#operator-impact-card {
    flex-grow: 2.5;
    flex-basis: 0;
    margin: 0 0 0 0.75rem;
}
.service-code-view-button-title {
    font-size: 1.25rem;
    color: var(--colour-1);
    font-weight: 500;
    margin-right: 0.75rem;
}
</style>