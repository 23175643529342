<!-- Displays a treemap chart  -->

<template>
    <Card scrollable="Vertical" :expandable="false">
        <template #header-left>
            {{title}}
        </template>
        <template #header-right>
            <Button @click="buttonClicked('speeds')" :class="{active: buttonIsActive('speeds')}"> no. of speeds </Button>
            <Button @click="buttonClicked('timeloss')" :class="{active: buttonIsActive('timeloss')}"> time loss </Button>
        </template>
        <template #body>
            <!--
            <div v-show="loading">
            LOADING
            </div>
            -->
            <div class="body">
                <div id="graph">
                    <!-- <div v-if="active_button==='speeds'" id="speeds-graph"></div>
                    <div v-else id="timeloss-graph"></div> -->
                </div>
            </div>
        </template>
    </Card>
</template>

<script>
import Card from 'src/components/home/common/Card.vue'
import Button from 'src/components/home/common/Button.vue'
import Plotly from 'plotly.js-dist'

export default {
    name: 'TreemapChart',
    components: {Card, Button},
    props: {
        data: {
            type: Promise,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        categoryLabel: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            active_button: "timeloss",
            tableData: null,
            loading: true
        }
    },
    watch: {
        data(promise) {
            promise.then(data => {
                this.tableData = data;
                this.plotDeliveryUnitTreemap()
            })
        }
    },
    methods: {
        buttonClicked(button_type) {
            if (button_type != this.active_button) {
                this.active_button = button_type
                this.plotDeliveryUnitTreemap()
            }
        },
        buttonIsActive(button_type) {
            if (button_type === this.active_button) {
                return true
            }
            else {
                return false
            }
        },
        plotDeliveryUnitTreemap() {
            let rem = parseFloat(getComputedStyle(document.documentElement).fontSize)
            let table;
            let column;
            let percent_column;
            let texttemplate;
            table = this.tableData
            if (this.active_button === "speeds") {
                column = "active_speed_restrictions"
                percent_column = "active_speed_restrictions_percentage"
                texttemplate = "<b>%{label}</b><br>%{customdata}% of speed restrictions<br>%{value} speed restrictions"
            }
            else if (this.active_button === "timeloss") {
                column = "daily_impact_minutes"
                percent_column = "daily_impact_percentage"
                texttemplate = "<b>%{label}</b><br>%{customdata}% of time loss<br>%{value} minutes time loss"
            }
            let labels = []
            let values = []
            let percents = []
            for (let row of table) {
                labels.push(row[this.categoryLabel])
                values.push(row[column])
                percents.push(row[percent_column])
            }
            
            let data = [{
                type: "treemap",
                labels: labels,
                values: values,
                customdata: percents,
                parents: Array(labels.length).fill(""),
                texttemplate: texttemplate,
                hovertemplate: "<b>%{label}</b><br>%{customdata}%<extra></extra>",
                hoverlabel: {
                    font: {
                        color: "#FFFFFF"
                    }
                },
                textfont: {
                    color: "#FFFFFF"
                },
                marker: {
                    colors: ["#101C5A", "#FFC944", "#E947BB", "#3153CB", "#101C5A", "#FFC944", "#E947BB", "#3153CB", "#101C5A", "#FFC944", "#E947BB", "#3153CB"],
                    line: {
                        width: 0
                    }
                },
                tiling: {
                    pad: 0
                }
            }]
            
            let layout = {
                font: {
                    family: "Inter",
                    size: 1 * rem
                },
                margin: {
                    l: 0,
                    r: 0,
                    t: 0,
                    b: 0,
                }
            }

            let config = {
                displayModeBar: false,
                responsive: true
            }

            Plotly.react("graph", data, layout, config)
                .then(gd => {
                    // prevent clicking on treemap
                    gd.on('plotly_treemapclick', () => false)
                    // remove the parent box of the treemap
                    this.$nextTick(() => {
                        let top_layer = gd.querySelector(".slice:first-child")
                        if (top_layer) {
                            top_layer.remove()
                        }
                    })
                })
        }
    },
    created() {
        this.data.then(data => {
            this.tableData = data;
            this.plotDeliveryUnitTreemap()
        })
    }
}
</script>


<style scoped>
.body {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.graph-title {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    font-size: 1.1rem;
    font-weight: bold;
}
#graph {
    flex-grow: 1;
    margin-bottom: 1rem;
}
.graph > div {
    height: 100%;
    width: 100%;
}
</style>
