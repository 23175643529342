<!-- Checks authentication on startup and routes to either loginpage or homepage -->

<!-- HomePage of the app -->

<template>
    <!-- component matched by the route will render here -->
    <router-view></router-view>
</template>

<script>
import "inter-ui"  // for font

export default {
  name: 'App'
}
</script>

<style>

#app {
  font-family: "Inter" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>