<!-- Vue component for displaying from/to data with icons -->

<template>
<div class="container">
    <div class="symbols">
        <div :class="fromSymbol" class="from-symbol"></div>
        <div class="vertical-line"></div>
        <div :class="toSymbol" class="to-symbol"></div>
    </div>
    <div class="data">
        <div class="from" :style="textStyle" ref="from">
            <slot name="from"/>
        </div>
        <div class="to" :style="textStyle" ref="to">
            <slot name="to"/>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "StyledFromTo",
    props: [
        "fromSymbol",
        "toSymbol",
        "noWrap",
        "maxWidth"
    ],
    computed: {
        textStyle() {
            return {
                "white-space": this.noWrap ? "nowrap" : null,
                "max-width": this.maxWidth ? `${this.maxWidth}rem` : null,
                "overflow": this.maxWidth ? "hidden" : null,
                "text-overflow": this.maxWidth ? "ellipsis" : null
            }
        }
    },
    methods: {
        /**
         * Adds the full text of an element as a hover label if it is overflowing
         * @param {MouseEvent}
         */
        setHoverLabelIfOverflowing(event) {
            let element = event.target;
            if (element.offsetWidth < element.scrollWidth && !element.title) {
                element.title = element.textContent
            }
        }
    },
    mounted() {
        if (this.maxWidth) {
            this.$refs.from.addEventListener(
                "mouseenter", this.setHoverLabelIfOverflowing
            );
            this.$refs.to.addEventListener(
                "mouseenter", this.setHoverLabelIfOverflowing
            );
        }
    }
}
</script>

<style scoped>
.container {
    display: flex;
    flex-direction: row;
}
.symbols {
    width: 0.5rem;
    display: flex;
    flex-direction: column;
    margin: 0.3rem 0.5rem 0.3rem 0;
}
.vertical-line {
    flex-grow: 1;
}
.from-symbol {
    height: 0.5rem;
}
.to-symbol {
    height: 0.5rem;
}
.from {
    margin-bottom: 0.5rem;
}
.filled-circle-light {
    background-color: var(--colour-10);
    border-radius: 50%;
}
.filled-circle-dark {
    background-color: var(--colour-9);
    border-radius: 50%;
}
.open-circle {
    background-color: var(--colour-4);
    border: 1px solid var(--colour-9);
    border-radius: 50%;
}
.filled-square-dark {
    background-color: var(--colour-9);
}
.vertical-line {
    border-left: 1px solid var(--colour-9);
    position: relative;
    left: 0.22rem;
}
</style>