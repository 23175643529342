<!--
    Displays the tsr periods for a given speed restriction reference
-->

<template>
    <div class="tsr-periods">
        <span class="date-ranges-text">Date ranges: </span>
        <span v-if="tsrPeriodsLoading">loading</span>
        <span v-else-if="tsrPeriods === null">no date ranges available</span>
        <span v-else class="dates">
            {{formatDate(tsrPeriods.before_tsr_start)}}
            - {{formatDate(tsrPeriods.before_tsr_end)}}
            (before) |
            {{formatDate(tsrPeriods.during_tsr_start)}}
            - {{formatDate(tsrPeriods.during_tsr_end)}}
            (during)
        </span>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "TsrPeriods",
    props: ["reference"],
    data() {
        return {
            tsrPeriods: null,
            tsrPeriodsLoading: true
        }
    },
    methods: {
        formatDate(date) {
            date = new Date(date)
            let datestring = date.toLocaleDateString()
            return datestring
        },
        getTsrPeriods() {
            this.tsrPeriodsLoading = true
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/individual/tsr-periods`,
                method: "GET",
                params: {reference: this.reference},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
            .then(response => {
                this.tsrPeriods = response.data
                this.tsrPeriodsLoading = false
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
                this.tsrPeriodsLoading = false
            })
        }
    },
    created() {
        this.getTsrPeriods()
    }
}
</script>

<style scoped>
.tsr-periods {
    margin: 1.25rem 0 1.25rem 0;
}
.date-ranges-text {
    font-weight: 500;
}
</style>