<!-- Displays the Scorecard -->

<template>
    <Card>
        <template #header-left>
            Scorecard
        </template>
        <template #header-right>
            <span class="buttons-scorecard">
                <Button @click="buttonClicked('region')" :class="{ active: buttonIsActive('region') }">
                    Region
                </Button>
                <Button @click="buttonClicked('route')" :class="{ active: buttonIsActive('route') }">
                    Route
                </Button>
                <Button @click="buttonClicked('toc')" :class="{ active: buttonIsActive('toc') }">
                    Operator
                </Button>
            </span>
        </template>
        <template #body>
            <div v-if="loading">
                LOADING
            </div>
            <!--  Use v-for to render all multiple of the element  -->
            <div v-else class="scroll-content">
                <div v-if="active == 'route' & !loadingNationalData" class="scorecard-element"
                    :class="{ active: activeTitle('National') }" @click="rowClicked('National')">
                    <span class="icon-container">
                        <i v-if="nationalData.tsr_arrow_direction === 'Up'" class="up pi pi-arrow-up" />
                        <i v-else-if="nationalData.tsr_arrow_direction === 'Horizontal'"
                            class="right pi pi-arrow-right" />
                        <i v-else-if="nationalData.tsr_arrow_direction === 'Down'" class="down pi pi-arrow-down" />
                        <i v-else class="none pi pi-minus" />
                    </span>
                    <span class="data-container">
                        <div>
                            <span class="title-scorecard-element" @click.stop="titleClicked('National')">National</span>
                        </div>
                        <div class="timeloss-scorecard-element">
                            <span> {{ formatMinutes(nationalData.tsr_total_daily_impact_minutes) }} </span> TSR time
                            loss per day
                        </div>
                        <div v-if="active == 'route'" class="contact-scorecard-element">
                            <i class="pi pi-user" /> Andrew Haines
                        </div>
                    </span>
                </div>
                <div v-for="row in scorecardData[active]" :key="row[active]" class="scorecard-element"
                    @click="rowClicked(row[active])" :class="{ active: activeTitle(row[active]) }">
                    <span class="icon-container">
                        <i v-if="row.total_arrow_direction === 'Up'" class="up pi pi-arrow-up" />
                        <i v-else-if="row.total_arrow_direction === 'Horizontal'" class="right pi pi-arrow-right" />
                        <i v-else-if="row.total_arrow_direction === 'Down'" class="down pi pi-arrow-down" />
                        <i v-else class="none pi pi-minus" />
                    </span>
                    <span class="data-container">
                        <div>
                            <span class="title-scorecard-element" @click.stop="titleClicked(row[active])">{{ row[active]
                                }}</span>
                        </div>
                        <div class="timeloss-scorecard-element">
                            <span> {{ formatMinutes(row.tsr_daily_impact_minutes_this_week) }} </span> TSR time loss per
                            day
                        </div>
                        <div v-if="active == 'route'" class="contact-scorecard-element">
                            <i class="pi pi-user" /> {{ row.route_director }}
                        </div>
                        <div v-else-if="active == 'region'" class="contact-scorecard-element">
                            <i class="pi pi-user" /> {{ row.region_director }}
                        </div>
                    </span>
                </div>
            </div>
        </template>
    </Card>
</template>

<script>
import Card from "../common/Card.vue"
import Button from "../common/Button.vue"
import axios from "axios"

export default {
    name: "Scorecard",
    components: {
        Card,
        Button
    },
    data() {
        return {
            active: this.$store.state.activeType,
            scorecardData: {
                route: null,
                toc: null,
                region: null
            },
            loading: true,
            loadingNationalData: true,
            nationalData: null
        }
    },
    computed: {
        activeRegion() {
            return this.$store.state.activeRegion
        },
        activeRoute() {
            return this.$store.state.activeRoute
        },
        activeToc() {
            return this.$store.state.activeToc
        },
        activeType() {
            return this.$store.state.activeType
        }
    },
    methods: {
        activeTitle(title) {
            if (
                (this.activeType === "route" & title === this.activeRoute)
                | (this.activeType === "toc" & title === this.activeToc)
                | (this.activeType === "region" & title === this.activeRegion)
            ) {
                return true
            }
            else {
                return false
            }
        },
        rowClicked(title) {
            if (this.active === "route") {
                this.$store.commit("setActiveRoute", title)
                this.$store.commit("setActiveType", "route")
            } else if (this.active === "toc") {
                this.$store.commit("setActiveToc", title)
                this.$store.commit("setActiveType", "toc")
            } else if (this.active === "region") {
                this.$store.commit("setActiveRegion", title)
                this.$store.commit("setActiveType", "region")
            }
        },
        titleClicked(title) {
            this.$router.push(`/home/route_operator/${this.active}/${title}`)
        },
        buttonClicked(button_type) {
            if (button_type != this.active) {
                this.active = button_type
            }
        },
        buttonIsActive(button_type) {
            if (button_type === this.active) {
                return true
            }
            else {
                return false
            }
        },
        formatMinutes(minutes) {
            if (minutes == null) {
                return "unknown"
            }
            minutes = minutes.toLocaleString("en-UK")
            if (minutes == 1) {
                minutes += " minute"
            }
            else {
                minutes += " minutes"
            }
            return minutes
        },
        getData() {
            this.loading = true
            let load_count = 0
            // get route scorecard data
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/overview/route-scorecard`,
                method: "GET",
                headers: {
                    "accept": "application/json",
                    "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
                .then(response => {
                    this.scorecardData.route = response.data
                    load_count += 1
                    if (load_count >= 3) { this.loading = false }
                })
                .catch(error => {
                    console.log(error)
                    if (error.response) {
                        console.log(error.response.status)
                    }
                })
            // get toc scorecard data
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/overview/toc-scorecard`,
                method: "GET",
                headers: {
                    "accept": "application/json",
                    "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
                .then(response => {
                    this.scorecardData.toc = response.data
                    load_count += 1
                    if (load_count >= 3) { this.loading = false }
                })
                .catch(error => {
                    console.log(error)
                    if (error.response) {
                        console.log(error.response.status)
                    }
                })
            // get region scorecard data
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/overview/region-scorecard`,
                method: "GET",
                headers: {
                    "accept": "application/json",
                    "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
                .then(response => {
                    this.scorecardData.region = response.data
                    load_count += 1
                    if (load_count >= 3) { this.loading = false }
                })
                .catch(error => {
                    console.log(error)
                    if (error.response) {
                        console.log(error.response.status)
                    }
                })
        },
        getNationalData() {
            this.loadingNationalData = true
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/route/kpi-total-time-loss-per-day`,
                method: "GET",
                headers: {
                    "accept": "application/json",
                    "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
                .then(response => {
                    this.nationalData = response.data
                    this.loadingNationalData = false
                })
                .catch(error => {
                    console.log(error)
                    if (error.response) {
                        console.log(error.response.status)
                    }
                })
        }
    },
    mounted() {
        this.getData()
        this.getNationalData()
    },
}
</script>

<style scoped>
/* Remove left and right padding from body of card (it is added to scorecard element instead for hover effects) */
::v-deep .body {
    padding-left: 0;
    padding-right: 0;
}

.scroll-content {
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
}

.scorecard-element {
    padding: 0.8rem 0rem 0.8rem 2rem;
    align-items: center;
    display: flex;
}

.scorecard-element:hover {
    background-color: var(--colour-2);
    cursor: pointer;
}

.scorecard-element:hover .title-scorecard-element,
.scorecard-element.active .title-scorecard-element {
    border-color: var(--colour-2);
}

.scorecard-element.active {
    background-color: var(--colour-2);
}

.icon-container {
    text-align: center;
    color: var(--colour-4);
    padding-right: 1.5rem;
}

.icon-container>i {
    border-radius: 100%;
    padding: 0.6rem;
    font-weight: bold;
    font-size: 2rem;
}

/* Set arrow icon colours */
.icon-container>i.up {
    background-color: var(--colour-7);
}

.icon-container>i.right {
    background-color: var(--colour-5);
}

.icon-container>i.down {
    background-color: var(--colour-8);
}

.icon-container>i.none {
    background-color: grey;
}

.data-container>div:nth-child(2) {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
}

.title-scorecard-element {
    color: var(--colour-1);
    font-weight: bold;
    font-size: 1.5rem;
    border: 0;
    border-color: var(--colour-4);
    border-style: solid;
    border-bottom-width: 2px;
}

.title-scorecard-element:hover {
    border-style: solid;
    border-bottom-width: 2px;
    border-color: var(--colour-1) !important;
}

.timeloss-scorecard-element>span {
    font-weight: bold;
}

.contact-scorecard-element>i {
    margin-right: 0.2rem;
}

.buttons-scorecard Button {
    font-size: 0.65rem;
}
</style>
