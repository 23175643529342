<template>
    <div id="management-content">
        <div class="left-cards">
            <div class="add-action" @click="addActionModal = true">
                <div class="content">
                    Add new action
                </div>
                <i class="sprint-plus-circle"/>
            </div>
            <div class="open-actions">
                <div v-if="loadingActionsCountData">
                    LOADING
                </div>
                <div v-else class="content">
                    <div class="title">Number of open actions</div>
                    <div class="value">{{ actionsCountData.open_count }}</div>
                    <div class="extra"><b>{{ actionsCountData.completed_count }}</b> completed actions</div>
                </div>
                <i class="sprint-activity-grid"/>
            </div>
            <TsrAttachments/>
        </div>
        <Card id="action-list">
            <template #header-left>
                Action list
            </template>
            <template #body>
                <div v-if="loadingActionsListData">
                    LOADING
                </div>
                <div v-else-if="actionsListData == null" class="no-actions-assigned-text">No actions assigned</div>
                <div v-else class="scroll-content">
                    <DataTable :value="actionsListData" :autoLayout="true">
                        <Column field="responsible" header="Responsible">
                            <template #body="{data}">
                                <span class="icon-responsible">
                                    <i class="sprint-profile-inverted"/>
                                </span>
                                <span class="cell-text-responsible">
                                    {{data.responsible}}
                                </span>
                            </template>
                        </Column>
                        <Column field="description" header="Action">
                        </Column>
                        <Column field="end_date" header="Due date">
                            <template #body="{data}">
                                {{ data.end_date == null ? null : new Date(data.end_date).toLocaleDateString() }}
                            </template>
                        </Column>
                        <Column field="status" header="Status">
                            <template #body="{data}">
                                <span v-if="data.status === 'complete'" class="cell-status green">
                                    Complete
                                </span>
                                <span v-else-if="data.is_comment" class="cell-status grey">
                                    Comment
                                </span>
                                <span v-else-if="data.end_date == null" class="cell-status yellow">
                                    Open
                                </span>
                                <span v-else-if="currentDate > new Date(data.end_date)" class="cell-status red">
                                    Missed date
                                </span>
                                <span v-else class="cell-status yellow">
                                    Open
                                </span>
                            </template>
                        </Column>
                        <Column>
                            <template #body="{data}">
                                <span class="action-buttons">
                                    <button class="action-button" @click="openEditActionModal(data)">
                                        <i class="sprint-edit"/>
                                    </button>
                                    <button class="action-button" @click="openDeleteActionModal(data)">
                                        <i class="bi bi-trash-fill"/>
                                    </button>
                                    <button class="action-button" @click="openCompleteActionModal(data)">
                                        <i class="pi pi-check"/>
                                    </button>
                                </span>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </template>
        </Card>
        <!-- Add Action Modal -->
        <div v-if="addActionModal" class="modal-mask" @click.self="addActionModal = false">
            <Card class="add-action-modal-container" :expandable="false">
                <template #header-left>
                    Add action
                </template>
                <template #body>
                    <div class="modal-card-body">
                        <div>
                            <label for="add-responsible" hidden>Responsible</label>
                            <input type="text" id="add-responsible" name="responsible" placeholder="Responsible">
                        </div>
                        <div>
                            <label for="add-description" hidden>Description</label>
                            <input type="text" id="add-description" name="description" placeholder="Description">
                        </div>
                        <div>
                            <div><b>Due date</b></div>
                            <label for="add-due-date" hidden>Due date</label>
                            <input type="date" id="add-due-date" name="due date">
                        </div>
                        <div class="comment-line">
                            <label for="add-is-comment"><b>Comment</b></label>
                            <input type="checkbox" id="add-is-comment" name="is comment">
                        </div>
                        <div class="modal-card-buttons">
                            <Button @click="createAction">
                                Create action
                            </Button>
                            <Button @click="addActionModal = false">
                                Cancel
                            </Button>
                        </div>
                    </div>
                </template>
            </Card>
        </div>
        <!-- Edit Action Modal -->
        <div v-if="editActionModal" class="modal-mask" @click.self="closeEditActionModal">
            <Card class="edit-action-modal-container" :expandable="false">
                <template #header-left>
                    Edit action
                </template>
                <template #body>
                    <div class="modal-card-body">
                        <div>
                            <label for="edit-responsible" hidden>Responsible</label>
                            <input type="text" id="edit-responsible" name="responsible" placeholder="Responsible" :value="actionToEdit.responsible">
                        </div>
                        <div>
                            <label for="edit-description" hidden>Description</label>
                            <input type="text" id="edit-description" name="description" placeholder="Description" :value="actionToEdit.description">
                        </div>
                        <div>
                            <div><b>Due date</b></div>
                            <label for="edit-due-date" hidden>Due date</label>
                            <input type="date" id="edit-due-date" name="due date" :value="actionToEdit.end_date">
                        </div>
                        <div class="comment-line">
                            <label for="edit-is-comment"><b>Comment</b></label>
                            <input type="checkbox" id="edit-is-comment" name="is comment" :checked="actionToEdit.is_comment">
                        </div>
                        <div class="modal-card-buttons">
                            <Button @click="updateAction">
                                Update action
                            </Button>
                            <Button @click="closeEditActionModal">
                                Cancel
                            </Button>
                        </div>
                    </div>
                </template>
            </Card>
        </div>
        <!-- Delete Action Modal -->
        <div v-if="deleteActionModal" class="modal-mask" @click.self="closeDeleteActionModal">
            <Card class="delete-action-modal-container" :expandable="false">
                <template #header-left>
                    Delete action?
                </template>
                <template #body>
                    <div class="modal-card-body delete">
                        <div>
                            <label for="delete-responsible" hidden>Responsible</label>
                            <input readonly type="text" id="delete-responsible" name="responsible" placeholder="Description" :value="actionToDelete.responsible">
                        </div>
                        <div>
                            <label for="delete-description" hidden>Description</label>
                            <input readonly type="text" id="delete-description" name="description" placeholder="Description" :value="actionToDelete.description">
                        </div>
                        <div>
                            <div><b>Due date</b></div>
                            <label for="delete-due-date" hidden>Due date</label>
                            <input readonly type="date" id="delete-due-date" name="due date" :value="actionToDelete.end_date">
                        </div>
                        <div class="comment-line">
                            <label for="delete-is-comment"><b>Comment</b></label>
                            <input disabled type="checkbox" id="delete-is-comment" name="is comment" :checked="actionToDelete.is_comment">
                        </div>
                        <div class="modal-card-buttons">
                            <Button @click="closeDeleteActionModal">
                                Cancel
                            </Button>
                            <Button @click="deleteAction">
                                Delete action
                            </Button>
                        </div>
                    </div>
                </template>
            </Card>
        </div>
        <!-- Complete Action Modal -->
        <div v-if="completeActionModal" class="modal-mask" @click.self="closeCompleteActionModal">
            <Card class="complete-action-modal-container" :expandable="false">
                <template #header-left>
                    Mark action as complete?
                </template>
                <template #body>
                    <div class="modal-card-body complete">
                        <div>
                            <label for="complete-responsible" hidden>Responsible</label>
                            <input readonly type="text" id="complete-responsible" name="responsible" placeholder="Description" :value="actionToComplete.responsible">
                        </div>
                        <div>
                            <label for="complete-description" hidden>Description</label>
                            <input readonly type="text" id="complete-description" name="description" placeholder="Description" :value="actionToComplete.description">
                        </div>
                        <div>
                            <div><b>Due date</b></div>
                            <label for="complete-due-date" hidden>Due date</label>
                            <input readonly type="date" id="complete-due-date" name="due date" :value="actionToComplete.end_date">
                        </div>
                        <div class="comment-line">
                            <label for="complete-is-comment"><b>Comment</b></label>
                            <input disabled type="checkbox" id="complete-is-comment" name="is comment" :checked="actionToComplete.is_comment">
                        </div>
                        <div class="modal-card-buttons">
                            <Button @click="closeCompleteActionModal">
                                Cancel
                            </Button>
                            <Button @click="completeAction">
                                Mark as complete
                            </Button>
                        </div>
                    </div>
                </template>
            </Card>
        </div>
    </div>
</template>

<script>
import Card from '../../common/Card.vue';
import Button from '../../common/Button.vue'
import DataTable from "primevue/datatable/sfc";
import Column from "primevue/column/sfc";
import axios from "axios";
import TsrAttachments from "./TsrAttachments.vue"

export default {
    name: "Management",
    components: {
        Card,
        Button,
        DataTable,
        Column,
        TsrAttachments
    },
    data() {
        return {
            actionsCountData: null,
            loadingActionsCountData: true,
            actionsListData: null,
            loadingActionsListData: true,
            addActionModal: false,
            editActionModal: false,
            deleteActionModal: false,
            completeActionModal: false,
            actionToEdit: null,
            actionToDelete: null,
            actionToComplete: null
        }
    },
    computed: {
        referenceNumber() {
            let ref = this.$store.state.activeIndividualSpeedRestriction
            return ref
        },
        currentDate() {
            return new Date()
        },
    },
    watch: {
        referenceNumber: function() {
            this.getData()
        }
    },
    methods: {
        openEditActionModal(data) {
            this.actionToEdit = data
            this.editActionModal = true
        },
        closeEditActionModal() {
            this.actionToEdit = null
            this.editActionModal = false
        },
        openDeleteActionModal(data) {
            this.actionToDelete = data
            this.deleteActionModal = true
        },
        closeDeleteActionModal() {
            this.actionToDelete = null
            this.deleteActionModal = false
        },
        openCompleteActionModal(data) {
            this.actionToComplete = data
            this.completeActionModal = true
        },
        closeCompleteActionModal() {
            this.actionToComplete = null
            this.completeActionModal = false
        },
        createAction() {
            // create action model
            let end_date = document.getElementById("add-due-date").value
            let actionModel = {
                description: document.getElementById("add-description").value,
                responsible: document.getElementById("add-responsible").value,
                end_date: (end_date === "") ? null : end_date,
                is_comment: document.getElementById("add-is-comment").checked,
                status: "open"
            }
            // send post request
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/actions/create-restriction-action`,
                method: "POST",
                data: actionModel,
                params: {reference: this.referenceNumber},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`,
                        "Content-Type": "application/json"
                }
            })
            .then(() => {
                this.getActionsListData()
                this.getActionsCountData()
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
            // modal to false
            this.addActionModal = false
        },
        updateAction() {
            // create action model
            let end_date = document.getElementById("edit-due-date").value
            let actionModel = {
                description: document.getElementById("edit-description").value,
                responsible: document.getElementById("edit-responsible").value,
                end_date: (end_date === "") ? null : end_date,
                is_comment: document.getElementById("edit-is-comment").checked,
                status: "open"
            }
            // send patch request
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/actions/update-restriction-action`,
                method: "PATCH",
                data: actionModel,
                params: {reference: this.referenceNumber,
                        id: this.actionToEdit.id
                },
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`,
                        "Content-Type": "application/json"
                }
            })
            .then(() => {
                this.getActionsListData()
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
            this.closeEditActionModal()
        },
        deleteAction() {
            // send delete request
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/actions/delete-restriction-action`,
                method: "DELETE",
                params: {reference: this.referenceNumber,
                        id: this.actionToDelete.id
                },
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
            .then(() => {
                this.getActionsListData()
                this.getActionsCountData()
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
            this.closeDeleteActionModal()
        },
        completeAction() {
            // send delete request
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/actions/complete-restriction-action`,
                method: "PATCH",
                params: {reference: this.referenceNumber,
                        id: this.actionToComplete.id
                },
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
            .then(() => {
                this.getActionsListData()
                this.getActionsCountData()
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
            this.closeCompleteActionModal()
        },
        getActionsListData() {
            this.loadingActionsListData = true
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/actions/get-restriction-actions`,
                method: "GET",
                params: {reference: this.referenceNumber},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
            .then(response => {
                this.actionsListData = response.data
                this.loadingActionsListData = false
            })
            .catch( error => {
                this.loadingActionsListData = false
                this.actionsListData = null
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        },
        getActionsCountData() {
            this.loadingActionsCountData = true
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/actions/get-restriction-actions-count`,
                method: "GET",
                params: {reference: this.referenceNumber},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
            .then(response => {
                this.actionsCountData = response.data
                this.loadingActionsCountData = false
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        },
        getData() {
            this.getActionsListData()
            this.getActionsCountData()
        }
    },
    created() {
        this.getData()
    }
}
</script>

<style scoped>
::v-deep .body {
    padding-right: 0;
}
.scroll-content {
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    padding-right: 1.5rem;
}
#management-content {
    height: 100%;
    display: flex;
}
.left-cards {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    margin-right: 0.75rem;
}
.add-action, .open-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem 1.5rem 0.3rem 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.add-action {
    background-color: var(--colour-12);
    margin-bottom: 5rem;
    color: var(--colour-4);
    font-size: 1.6rem;
    font-weight: bold;
}
.add-action:hover {
    cursor: pointer;
}
.add-action i {
    color: var(--colour-4);
}
i {
    font-size: 3.5rem;
}
.open-actions {
    background-color: var(--colour-4);
}
.open-actions .value {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--colour-1);
}
.open-actions .title {
    font-size: 1.1rem;
    color: var(--colour-6);
}
.open-actions .extra {
    font-size: 0.9rem;
    color: var(--colour-6);
}
.open-actions i {
    color: var(--colour-6);
}
.open-actions {
    margin-bottom: 1.5rem;
}

#action-list {
    flex-grow: 4;
    flex-basis: 0;
    margin: 0 0 0 0.75rem;
}
::v-deep #action-list .p-datatable-thead th {
    font-size: 1.3rem !important;
}
::v-deep #action-list .p-datatable-tbody td{
    font-size: 1.3rem;
}
.cell-text-responsible {
    font-size: 1.5rem;
    color: var(--colour-1);
    font-weight: bold;
    margin-left: 1.5rem;
}
.icon-responsible > i{
    font-size: 1.8rem;
    font-weight: bold;
}
.cell-status {
    font-weight: bold;
    padding: 0 1rem 0 1rem;
    border-style: solid;
    border-width: 2px;
    border-radius: 0.5rem;
}
.cell-status.red {
    color: var(--colour-7);
    border-color: var(--colour-7);
}
.cell-status.yellow {
    color: var(--colour-5);
    border-color: var(--colour-5);
}
.cell-status.green {
    color: var(--colour-12);
    border-color: var(--colour-12);
}
.cell-status.grey {
    color: var(--colour-11);
    border-color: var(--colour-11);
}

/* Action modals */
.modal-mask {
position: fixed;
z-index: 9998;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
justify-content: center;
align-items: center;
}
.add-action-modal-container, .edit-action-modal-container, .delete-action-modal-container, .complete-action-modal-container {
width: 30vw;
height: 40vh;
}
.modal-card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}
.modal-card-body input:not([type='checkbox']) {
    width: calc(100% - 1.5rem);
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    margin-right: 1.5rem;
    font-family: "Inter";
    font-size: 1rem;
    color: var(--colour-6);
    border-radius: 0.5rem;
    border-style: solid;
    border-color: var(--colour-2);
    outline: none;
}
.modal-card-body:not(.delete, .complete) input:not([type='checkbox']):hover {
    border-color: var(--colour-3);
}
.modal-card-body:not(.delete, .complete) input:not([type='checkbox']):focus {
    border-color: var(--colour-1);
}
.modal-card-body input[type='checkbox'] {
    transform: scale(1.25);
    margin-left: 1rem;
}
.comment-line {
    display: flex;
    align-items: center;
}
.modal-card-buttons {
    display: flex;
    justify-content: center;
}
.modal-card-buttons :deep(button) {
    font-size: 1.25rem;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    margin: 0 0.75rem 0 0.75rem;
}

/* Action buttons */
.action-buttons {
    display: flex;
    justify-content: space-around;
}
.action-button {
    background-color: transparent;
    outline: none;
    border: 0;
    padding: .75rem;
    border-radius: 10%;
    width: 4rem;
}
.action-button:hover {
    cursor: pointer;
    background-color: var(--colour-2);
}
.action-button > .bi-trash-fill {
    font-size: 2.25rem;
    font-weight: bold;
    color: var(--colour-7)
}
.action-button > .pi-check {
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--colour-8)
}
.no-actions-assigned-text {
    font-size: 1.25rem;
    margin-top: 2rem;
    text-align: center;
}
</style>
