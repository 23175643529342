<!-- Bar graph component  -->

<template>
    <Card>
        <template #header-left>
            <span
                class="title" 
                :class="{
                    active: 'primary' === active_button && secondaryTitle,
                    button: secondaryTitle
                }"
                @click="titleClicked('primary')"
            >
                {{primaryTitle}}
            </span>
        </template>
        <template #header-right>
            <span v-if="secondaryTitle"
                class="title button" :class="{active: 'secondary' === active_button}"
                @click="titleClicked('secondary')"
            >
                {{secondaryTitle}}
            </span>
        </template>
        <template #body>
            <div v-if="dataLoading[active_button]">
            LOADING
            </div>
            <div v-else class="scroll-content">
                <div v-for="row in data[active_button]" :key="row[columns[active_button].label]" class="table-row">
                    <span class="toc-cell">
                        {{row[columns[active_button].label]}}
                    </span>
                    <span class="bar-cell">
                        <span class="bar" :style="getBarSizeStyle(row[columns[active_button].value])">
                            {{formatMinutes(row[columns[active_button].value])}}
                        </span>
                        <span class="percentage">{{row[columns[active_button].percentage]}}%</span>
                    </span>
                </div>
            </div>
        </template>
    </Card>
</template>

<script>
import Card from 'src/components/home/common/Card.vue'

export default {
    name: 'BarGraph',
    components: {Card},
    props: {
        primaryTitle: {
            type: String,
            required: true
        },
        primaryData: {
            type: Promise,
            required: true
        },
        primaryColumns: {
            label: {
                type: String,
                required: true
            },
            value: {
                type: Number,
                required: true
            },
            percentage: {
                type: Number,
                required: true
            }
        },
        secondaryTitle: {
            type: String,
            required: false
        },
        secondaryData: {
            type: Promise,
            required: false
        },
        secondaryColumns: {
            label: {
                type: String,
                required: false
            },
            value: {
                type: Number,
                required: false
            },
            percentage: {
                type: Number,
                required: false
            }
        },
    },
    largestBarSizeRem: 14,
    data() {
        return {
            data: {
                primary: null,
                seconday: null
            },
            dataLoading: {
                primary: true,
                seconday: true
            },
            columns: {
                primary: this.primaryColumns,
                secondary: this.secondaryColumns
            },
            active_button: "primary"
        }
    },
    computed: {
        rem() {
            return parseFloat(getComputedStyle(document.documentElement).fontSize)
        },
        barSizeToRemConversion() {
            let maxBarSize = 0
            for (let row of this.data[this.active_button]) {
                if (row.daily_impact_minutes > maxBarSize) {
                    maxBarSize = row.daily_impact_minutes
                }
            }
            return this.$options.largestBarSizeRem / maxBarSize
        }
    },
    watch: {
        primaryData(promise) {
            this.dataLoading.primary = true;
            promise.then(data => {
                this.data.primary = data;
                this.dataLoading.primary = false;
            })
        },
        secondaryData(promise) {
            if (this.secondaryTitle) {
                this.dataLoading.secondary = true;
                promise.then(data => {
                    this.data.secondary = data;
                    this.dataLoading.secondary = false;
                })
            }
            else {
                this.active_button = "primary"
            }
        },
        primaryColumns(columns) {
            this.columns.primary = columns
        },
        secondaryColumns(columns) {
            this.columns.secondary = columns
        }
    },
    methods: {
        titleClicked(title) {
            this.active_button = title
        },
        getBarSize(dailyTimeloss) {
            //convert time loss to size in rem
            let size = dailyTimeloss * this.barSizeToRemConversion
            return size
        },
        getBarSizeStyle(dailyTimeloss) {
            return {width: this.getBarSize(dailyTimeloss) + 'rem'}
        },
        formatMinutes(minutes) {
            if (this.getBarSize(minutes) < (this.$options.largestBarSizeRem * 0.5)) {
                return null
            }

            if (minutes == null) {
                minutes = "Unknown"
            }
            else {
                minutes = minutes.toLocaleString("en-UK")
                if (minutes == 1) {
                    minutes += " minute"
                }
                else {
                    minutes += " minutes"
                }
            }
            return minutes
        }
    },
    created() {
        this.primaryData.then(data => {
            this.data.primary = data
            this.dataLoading.primary = false
        })
        if (this.secondaryTitle) {
            this.secondaryData.then(data => {
                this.data.secondary = data
                this.dataLoading.secondary = false
            })
        }
    }
}
</script>


<style scoped>
:deep(.body) {
    padding-right: 0;
}
:deep(.header) {
    padding-bottom: 0;
}
.title {
    font-weight: bold;
    font-size: 1.5rem;
    border-bottom-style: solid;
    border-bottom-width: 0.25rem;
    border-bottom-color: transparent;
}
.title.active, .title.button:hover {
    border-color: var(--colour-1);
}
.title.button:not(.active):hover{
    cursor: pointer;
}
.title.active:hover {
    cursor: default;
}
.scroll-content {
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    padding-right: 1.5rem;
}
.table-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.table-row:first-child {
    margin-top: 0.5rem;
}
.toc-cell {
    padding: 0.5rem 0.1rem 0.5rem 0;
    font-size: 1.1rem;
    width: 12.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-right: solid;
    border-right-width: 1px;
    border-right-color: var(--colour-3);
}

.bar {
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    color: var(--colour-4);
    background-color: var(--colour-1);
    border-radius: 0 0.2rem 0.2rem 0;
    margin-right: 0.5rem;
    padding-right: 0.5rem;
    font-size: 0.85;
    height: 1.5rem;
    overflow: hidden;
}
.percentage {
    font-size: 1.25rem;
    font-weight: bold;
    color: var(--colour-1);
}
</style>
