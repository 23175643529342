<template>
    <nav id="navigation-header">
        <span class="left">
            <span id="logo">
                <img src="@/assets/logo_white.png" />
                <span class="text">
                    <div class="title">S P R I N T</div>
                    <div class="under-title">Speed Restriction Intelligence</div>
                </span>
            </span>
            <span>
                <span id="navigation-tabs">
                    <button @click="tabClick('OverviewContent')" :class="{ active: isActive('OverviewContent') }">
                        Overview
                    </button>
                    <button @click="tabClick('RouteOperatorPage')" :class="{ active: isActive('RouteOperatorPage') }">
                        Route/Operator
                    </button>
                    <button @click="tabClick('IndividualContent')" :class="{ active: isActive('IndividualContent') }">
                        Individual SR
                    </button>
                    <button @click="tabClick('ManagementContent')" :class="{ active: isActive('ManagementContent') }">
                        Management
                    </button>
                </span>
                <div v-if="status !== null && status !== ''" class="status-bar">
                    <i class="pi pi-exclamation-circle"></i>
                    <span class="status" v-html="status">
                    </span>
                </div>
            </span>
        </span>
        <span class="note-span">
            NOTE: ESR data is gathered from <a href=" https://nrsdb.uk/login.php" target="_blank">this tool</a>, which
            is currently used by the following routes only:
            <strong> {{ this.$store.state.routesUsingEsrTool.join(", ") }} </strong>.
        </span>
        <AccountInfoDropdown />
    </nav>
</template>

<script>
import AccountInfoDropdown from './AccountInfoDropdown.vue';
import axios from "axios";
import rnc from "src/functions/reference_number_conversion.js";

export default {
    name: 'HeaderBar',
    components: {
        AccountInfoDropdown
    },
    data() {
        return {
            status: null
        }
    },
    computed: {
        currentPageContent() {
            return this.$store.state.activeHomePageTab
        }
    },
    methods: {
        isActive(name) {
            if (name == this.currentPageContent) {
                return true
            }
            else {
                return false
            }
        },
        tabClick(tabName) {
            /*
            * Pushes a new path to the router
            */
            const conv = {
                "IndividualContent": "individual/overview",
                "OverviewContent": "overview",
                "RouteOperatorPage": "route_operator",
                "ManagementContent": "management"
            }
            const params = {
                "IndividualContent": rnc.referenceToUriReference(
                    this.$store.state.activeIndividualSpeedRestriction
                ),
                "RouteOperatorPage": this.$store.state.activeType
            }
            let param;
            if (params[tabName]) {
                param = params[tabName]
            }
            else {
                param = ""
            }
            this.$router.push(`/home/${conv[tabName]}/${param}`)
        },
        getWebsiteStatus() {
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/status/website-status`,
                method: "GET",
                params: { reference: this.referenceNumber },
                headers: {
                    "accept": "application/json",
                    "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
                .then(response => {
                    this.status = response.data
                })
                .catch(error => {
                    console.log(error)
                    if (error.response) {
                        console.log(error.response.status)
                    }
                })
        }
    },
    created() {
        this.getWebsiteStatus()
    }
}
</script>

<style scoped>
#navigation-header {
    width: 100%;
    height: 5rem;
    background-color: var(--colour-1);
    ;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--colour-4);
    position: fixed;
    z-index: 2;
    /* To display above primevue tables */
    padding: 0 1rem 0 1rem;
}

#navigation-tabs {
    display: flex;
    justify-content: center;
}

#navigation-tabs button {
    color: var(--colour-4);
    background-color: var(--colour-1);
    border-style: solid;
    border-width: 0;
    border-bottom-width: 1px;
    padding-bottom: 0.25rem;
    border-color: var(--colour-1);
    font-size: 1.3rem;
}

#navigation-tabs button:not(:first-child) {
    margin-left: 1rem;
}

#navigation-tabs button.active {
    border-color: var(--colour-4);
}

#navigation-tabs button:hover:not(.active) {
    border-color: var(--colour-2);
    cursor: pointer;
}

.left {
    display: flex;
    align-items: center;
}

/*
    Logo Styling
*/
#logo {
    display: flex;
    flex-direction: row;
    color: var(--colour-4);
    align-items: center;
    margin-right: 3.5rem;
}

#logo>img {
    height: 3.5rem;
    margin-right: 1rem;
}

#logo>.text>.title {
    font-weight: bold;
    font-size: 2rem;
}

#logo>.text>.under-title {
    font-size: 0.8rem;
    color: var(--colour-11);
}

/*
    Website status styling
*/
.status-bar {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
}

.status-bar>i {
    color: var(--colour-2);
    font-weight: bold;
    font-size: 1.1rem;
    margin-right: 0.5rem;
}

.status {
    color: var(--colour-2);
    font-size: 0.7rem;
    white-space: nowrap;
}

.note-span {
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    font-weight: 600;
    color: #9C1506;
    background-color: #D9E7F9;
    padding: 0.5%;
    width: 30%;
    border-radius: 10px;
}
</style>
