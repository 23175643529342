<!-- Common vue component used for buttons -->

<template>
    <button>
        <slot/>
    </button>
</template>

<script>
export default {
    name: "Button"
}
</script>

<style scoped>
button {
    background-color: transparent;
    border: 0.075rem;
    border-style: solid;
    border-color: var(--colour-1);
    border-radius: 0.25rem;
    color:  var(--colour-1);
    font-family: "Inter";
    font-size: 1rem;
    margin: 0 0.25rem 0 0.25rem;
    padding: 0.1rem 0.25rem 0.1rem 0.25rem;
}
button:hover {
    background-color: var(--colour-1);
    color: var(--colour-4);
    cursor: pointer;
}
button.active {
    font-weight: bold;
    color: var(--colour-4);
    background-color: var(--colour-1);
}
</style>