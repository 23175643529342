<!-- Common vue component used for dropdown menu with search -->

<template>
<span class="search-dropdown-container">
    <span class="header" @click="dropdownToggle" :style="headerStyling">
        <i
            class="pi"
            :class="{
                'pi-chevron-down': !dropdownActive,
                'pi-chevron-up': dropdownActive
            }"
            :style="headerIconStyling"
        />
        <span class="active-item" ref="activeItem" :style="headerTextStyling">
            {{ headerText }}
        </span>
    </span>
    <div class="content-container">
        <div class="dropdown-container" :style="dropdownStyling" v-if="dropdownActive">
            <input class="search-bar"
                type="text"
                placeholder="search"
                v-model="searchString"
                ref="textInput"
            >
            <ul class="item-list-container">
                <li class="item"
                    v-for="item in filteredItemList" :key="item"
                    @click="dropdownItemSelected(item)"
                    :class="{
                        'active': activeItem ?
                            item.toUpperCase() === activeItem.toUpperCase() :
                            false
                    }"
                >
                    {{item}}
                </li>
            </ul>
        </div>
    </div>
</span>
</template>

<script>
export default {
    name: "SearchDropdown",
    props: {
        // a list of options for the dropdown
        itemListPromise: {
            type: Promise,
            required: true
        },
        // whether or not the items in list need to be upper cased for searching
        // (should be true unless all items are already all upper case)
        // useful for speeding up search for large lists if items already upper
        // case
        itemsNeedUpperCasing: {
            type: Boolean,
            required: false,
            default: true
        },
        // the header text to display
        headerText: {
            type: String,
            required: true
        },
        // the currently active dropdown option
        activeItem: {
            type: String,
            required: false
        },
        // the header size (in rem)
        headerSize: {
            type: Number,
            required: false,
            default: 1.5
        },
        // the header font-weight (in rem)
        headerWeight: {
            type: Number,
            required: false,
            default: 700
        },
        // max width (in rem) of header
        headerMaxWidth: {
            type: Number,
            required: false,
            default: 100
        },
        // the header colour
        headerColour: {
            type: String,
            required: false,
            default: "var(--colour-1)"
        },
        // whether or not the header should wrap
        headerNoWrap: {
            type: Boolean,
            required: false,
            default: true
        },
        // route/operator/toc dropdown width (in %)
        dropdownWidth: {
            type: Number,
            required: false,
            default: 100
        }
    },
    data() {
        return {
            // the search string input by the user
            searchString: "",
            // whether or not the dropdown is displayed
            dropdownActive: false,
            // list of items in dropdown
            itemList: []
        }
    },
    computed: {
        /**
         * Filters a list of strings based on a search string
         * 
         * A string is filtered out if it doesn't contain the search string
         *
         * @returns {Array} - the filtered array
         */
        filteredItemList() {
            const filteredList = this.itemList.filter((item) => {
                if (this.itemsNeedUpperCasing) {
                    item = item.toUpperCase()
                }
                return item.includes(
                    this.searchString.toUpperCase()
                )
            })
            return filteredList;
        },
        headerTextStyling() {
            return {
                "font-size": `${this.headerSize}rem`,
                "font-weight": this.headerWeight,
                "max-width": `${this.headerMaxWidth}rem`,
                "white-space": this.headerNoWrap ? "nowrap" : null,
                "overflow": this.headerNoWrap ? "hidden" : null,
                "text-overflow": this.headerNoWrap ? "ellipsis" : null,
            }
        },
        headerIconStyling() {
            return {
                "font-size": `${this.headerSize*0.8}rem`,
                "font-weight": this.headerWeight
            }
        },
        headerStyling() {
            return {
                "color": this.headerColour
            }
        },
        dropdownStyling() {
            return{ 
                "width": `${this.dropdownWidth}%`
            }
        }
    },
    watch: {
        itemListPromise(promise) {
            promise.then(data => {
                this.itemList = data
            })
        }
    },
    methods: {
        /**
         * Emits an event for when a dropdown item is selected and closes menu
         * 
         * The name of the event is "itemSelected" and the data is the
         * selected item
         * 
         * @param {String} item - The string selected in the dropdown menu
         */
        dropdownItemSelected(item) {
            this.$emit("itemSelected", item)
            this.searchString = ""
            this.dropdownActive = false
        },
        /**
         * Toggles display of he dropdown menu
         */
        dropdownToggle() {
            this.searchString = ""
            this.dropdownActive = !this.dropdownActive
            // auto-focuses text input when opening dropdown menu
            if (this.dropdownActive) {
                this.$nextTick(() => {this.$refs.textInput.focus()})
            }
        },
        /**
         * checks if click event occured outside element and closes dropdown
         */
        dropdownOff(event) {
            if (!this.$el.contains(event.target)) {
                this.searchString = ""
                this.dropdownActive = false
            }
        }
    },
    mounted () {
        document.addEventListener('click', this.dropdownOff)
    },
    beforeUnmount () {
        document.removeEventListener('click',this.dropdownOff)
    },
    created() {
        this.itemListPromise.then(data => {
            this.itemList = data
        })
    }
}
</script>

<style scoped>
/**
* .search-dropdown-container is 'inline-block' to be a containing block for
* .content-container. .content-container is 'relative' to be a container block
* for .dropdown-container.
*
* this is done so that the dropdown container matches it's width to the content
* box of .search-dropdown-container, even if .search-dropdown-container has
* margin/padding applied by a parent.
* (this is because the containing block for 'absolute' is the PADDING box of
* the nearest 'absolute'/'relative'/'fixed' positioned parent)
*/
.search-dropdown-container {
    /* To contain the width of the 'absolute' positioned dropdown menu */
    display: inline-block;
}
.content-container {
    /* forces dropdown-container to respect padding/margin of container */
    position: relative;
}
.header {
    display: inline-flex;
    align-items: center;
}
.active-item {
    display: inline-block;
    font-size: 2.0rem;
}
.header > i {
    margin-right: 1rem;
}
.header:hover, .dropdown-content:hover {
    cursor: pointer;
}

.dropdown-container {
    position: absolute;
    width: 100%;
    background-color: var(--colour-4);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius:  0 0 0.5rem 0.5rem;
    border: solid;
    border-color: rgba(157, 160, 165, 0.63);
    border-width: 1px;
    font-size: 0.9rem;
    z-index: 999;
    color: var(--colour-6);
    font-weight: 400;
    padding: 0.75rem 1rem 0 1rem;
}
.search-bar {
    margin-bottom: 0.5rem;
    color: inherit;
    font-weight: inherit;
    font-size: inherit;
    font-family: inherit;
    width: 100%;
    height: 2rem;
    border-style: solid;
    border-color: var(--colour-2);
    border-radius: 0.5rem;
    border-width: 1px;
    padding-left: 0.5rem;
    outline: 0;
}
.item-list-container {
    overflow: auto;
    overflow-x: hidden;
    max-height: 30vh;
    margin: 0;
    padding: 0;
}
.item-list-container:hover {
    cursor: pointer;
}
.item {
    list-style-type: none;
    padding: 0.5rem 0 0.5rem 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.item:hover, .item.active {
    font-weight: bold;
}
</style>
