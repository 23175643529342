// Import for vuex 
import { createStore } from 'vuex'
// Import authenticator
import { vuexAuthenticator } from './authenticator.js'
// Import vuex persistence
import VuexPersistence from 'vuex-persist';

// initialise vuex persistence plugin for persist authenticator in local storage
const vuexLocalStorage = new VuexPersistence({
    storage: window.localStorage,
    reducer: (state) => ({
        authenticator: {
            token: state.authenticator.token,
            username: state.authenticator.username,
            authorised: state.authenticator.authorised
        }
    })
  });

// Create vuex store
const store = createStore({
    plugins: [vuexLocalStorage.plugin],
    modules: {
        authenticator: vuexAuthenticator
    },
    state: () => ({
        activeHomePageTab: "OverviewContent",
        activeIndividualSpeedRestriction: null,
        activeRoute: "National",
        activeToc: "Avanti West Coast",
        activeRegion: "Eastern",
        activeType: "route",
        routesUsingEsrTool: ['Anglia', 'East Midlands', 'North West', 'Sussex', 'West Coast South'] // add to list as more routes start using ESR tool 
    }),
    mutations: {
        setActiveHomePageTab(state, tab) {
            state.activeHomePageTab = tab
        },
        setActiveIndividualSpeedRestriction(state, referenceNumber) {
            state.activeIndividualSpeedRestriction = referenceNumber
        },
        setActiveRoute(state, route) {
            state.activeRoute = route
        },
        setActiveToc(state, toc) {
            state.activeToc = toc
        },
        setActiveRegion(state, region) {
            state.activeRegion = region
        },
        setActiveType(state, type) {
            state.activeType = type
        }
    }
})

export {store}