<!--
    Journeys tab of Individual page
-->

<template>
<Card :expandable="false" id="journeys-card">
    <template #header-left>
        Journeys
    </template>
    <template #body>
        <div class="body">
            <div class="table">
                <div v-if="loading.serviceImpact || loading.operatorImpact">
                    LOADING
                </div>
                <!-- Loop through Tocs -->
                <div v-else v-for="tocRow in operatorData" :key="tocRow.toc_id" class="toc-row">
                    <!-- Toc header -->
                    <div class="toc-data" @click="dropdownButtonClick(tocRow.toc_id)" 
                        :class="{open: dropdownOpen[tocRow.toc_id], 'has-top-gap': hasTopGap(tocRow.toc_id)}"
                    >
                        <span class="left">
                            <span class="toc-name">{{tocRow.toc}}</span>
                            <i class="pi pi-circle-on"/>
                            <span class="toc-impact">{{formatUnit(tocRow.daily_impact_minutes, "minute")}}</span>
                        </span>
                        <span class="right">
                            <span v-html="formatPunctualityInfo(tocRow.would_have_been_ontime, tocRow.would_have_been_t3)"/>
                            <button class="dropdown-button">
                                <i v-if="!dropdownOpen[tocRow.toc_id]" class="pi pi-chevron-down"></i>
                                <i v-else class="pi pi-chevron-up"></i>
                            </button>
                        </span>
                    </div>
                    <!-- Loop through service rows -->
                    <div v-if="dropdownOpen[tocRow.toc_id]" class="service-data">
                        <div class="service-table">
                            <div v-if="filteredServiceImpact(tocRow.toc_id).length === 0" class="service-unavailable-text">
                                No plots available for this operator
                            </div>
                            <div v-else v-for="serviceRow in filteredServiceImpact(tocRow.toc_id)" 
                                :key="getServiceId(serviceRow)" class="service-row"
                                :class="{active: activeService === getServiceId(serviceRow)}"
                                @click="serviceClick(serviceRow)"
                            >
                                <span>
                                    <div class="service-info">
                                        <span class="service-code">{{serviceRow.service_code}}</span>
                                        <i class="pi pi-circle-on"/>
                                        <span class="service-route">{{serviceRow.route_summary}}</span>
                                    </div>
                                    <div class="service-sub-info">
                                        <span class="service-impact">{{serviceRow.impact_s}}s per train</span>
                                        <span class="service-train-count">{{serviceRow.daily_train_count}} trains per day</span>
                                    </div>
                                </span>
                                <i v-if="getServiceId(serviceRow) === activeService" class="radio pi pi-circle-on"/>
                                <i v-else class="radio pi pi-circle-off"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="graph">
                <div v-if="activeServiceData !== null" class="graph-header">
                    <!-- Buttons to change plot -->
                    <span v-if="activeServiceData.journey_plot_filenames.length >= 2" class="controls">
                        <button @click="changeActivePlot(-1)"><i class="pi pi-chevron-left"></i></button>
                        <span>{{activePlotIndex + 1}} of {{activeServiceData.journey_plot_filenames.length}}</span>
                        <span class="plot-train-count"> ({{activeServiceData.daily_train_counts_per_journey[activePlotIndex]}} trains per day)</span>
                        <button @click="changeActivePlot(1)"><i class="pi pi-chevron-right"></i></button>
                    </span>
                    <span v-else class="plot-train-count"> ({{activeServiceData.daily_train_counts_per_journey[activePlotIndex]}} trains per day)</span>
                </div>
                <div v-if="graphLoading">LOADING</div>
                <div v-else v-html="graphDiv" style="height: 100%;width: 100%;"></div>
            </div>
        </div>
    </template>
</Card>
</template>

<script>
import Card from '../common/Card.vue'
import axios from "axios";

export default {
    name: 'Journeys',
    components: {
        Card
    },
    data() {
        return {
            dropdownOpen: null,
            activeToc: null,
            activeService: null,
            activeServiceData: null,
            activePlotIndex: 0,
            serviceData: null,
            operatorData: null,
            loading: {
                operatorImpact: true,
                serviceImpact: true
            },
            graphLoading: false,
            graphDiv: null
        }
    },
    computed: {
        referenceNumber() {
            // reference number for currently selected speed restriction
            let ref = this.$store.state.activeIndividualSpeedRestriction
            return ref
        }
    },
    watch: {
        graphLoading: (function(val) {
            // runs the plotly graph script once the virtual DOM has loaded
            if (!val) {
                this.$nextTick(
                    () => {
                        // get script
                        let script = document.querySelector(".graph script").innerHTML
                        // run script
                        eval(script)
                    }
                )
            }
        }),
        referenceNumber: function () {
            this.getServiceImpactData()
            this.getOperatorImpactData()
        }
    },
    methods: {
        createDropdownOpenObject() {
            // initialises the 'dropdownOpen' object, where each key 
            //    is a boolean corrosponding to whether a toc's dropdown is open or not
            let dropdownOpen = {}
            for (let row of this.operatorData) {
                dropdownOpen[row.toc_id] = false
            }
            this.dropdownOpen = dropdownOpen
        },
        dropdownButtonClick(toc_id) {
            // Triggered when a toc dropdown is clicked
            //      - Closes/opens the dropdown
            //      - Resets the activeToc and activeService if dropdown is being closed
            this.dropdownOpen[toc_id] = !this.dropdownOpen[toc_id]
            // if toc was active, reset active toc and active service
            if (this.activeToc === toc_id) {
                this.activeToc = null
                this.activeService = null
            }
        },
        changeActivePlot(change) {
            // changes which plot index is displayed for the active service
            let new_index = this.activePlotIndex + change
            let plots_count = this.activeServiceData.journey_plot_filenames.length
            if (new_index < 0 ) {
                new_index += plots_count
            }
            else if (new_index >= plots_count) {
                new_index -= plots_count
            }
            this.activePlotIndex = new_index
            this.getGraphData(this.activeServiceData, this.activePlotIndex)
        },
        filteredServiceImpact(toc_id) {
            // Returns the service data for the corrosponding TOC
            return this.serviceData.filter(
                row => row.toc_id === toc_id 
                // && row.daily_impact_minutes !== null
            )
        },
        getServiceId(serviceRow) {
            // creates a unique ID for the given service
            return `${serviceRow.toc_id}_${serviceRow.service_code}_${serviceRow.route_summary}`
        },
        serviceClick(serviceRow) {
            // Triggered when a service is clicked
            //      - sets the service to active (does nothing if already active)
            //      - gets the journey view plot for that service
            let id = this.getServiceId(serviceRow)
            if (id === this.activeService) {
                return
            }
            this.activeService = id
            this.activeToc = serviceRow.toc_id
            this.activeServiceData = serviceRow
            this.activePlotIndex = 0
            this.getGraphData(serviceRow, this.activePlotIndex)
        },
        formatPunctualityInfo(formattedOntime, formattedT3) {
            //  Formats the punctuality data for a TOC header row
            if (formattedOntime == null && formattedT3 !== null) {
                return `<span class="punctuality-text"><span class="percentage">${formattedT3}%</span> T-3</span>`
            }
            else if (formattedOntime !== null && formattedT3 == null) {
                return `<span class="punctuality-text"><span class="percentage">${formattedOntime}%</span> On-Time</span>`
            }
            else if (formattedOntime == null && formattedT3 == null) {
                return null
            }
            else {
                return `<span class="punctuality-text"><span class="percentage">${formattedOntime}%</span> On-Time | <span class="percentage">${formattedT3}%</span> T-3</span>`
            }
        },
        formatUnit(impactNumber, unit) {
            // Reformates time loss values to either add 'unit/s' or replace with 'unknown'
            //   but displayed on the table as 'Unknown'
            if (impactNumber == null) {
                impactNumber = "Unknown"
            }
            else {
                impactNumber = impactNumber.toLocaleString("en-UK")
                if (impactNumber == 1) {
                    impactNumber += " " + unit
                }
                else {
                    impactNumber += " " + unit + "s"
                }
            }
            return impactNumber
        },
        hasTopGap(toc_id) {
            // checks whether toc above in table has dropdown open
            // (this is needed so the element knows whether to round it's top borders or not)
            let previous_toc = null
            for (let row of this.operatorData) {
                if (toc_id === row.toc_id) {
                    if (this.dropdownOpen[previous_toc]) {
                        return true
                    }
                    else {
                        return false
                    }
                }
                previous_toc = row.toc_id
            }
            return false

        },
        getOperatorImpactData() {
            this.loading.operatorImpact = true
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/individual/operator-impact`,
                method: "GET",
                params: {reference: this.referenceNumber},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
            .then(response => {
                this.operatorData = response.data
                this.createDropdownOpenObject()
                this.loading.operatorImpact = false
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        },
        getServiceImpactData() {
            this.loading.serviceImpact = true
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/individual/train-service-impact`,
                method: "GET",
                params: {reference: this.referenceNumber},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
            .then(response => {
                this.serviceData = response.data
                this.loading.serviceImpact = false
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        },
        getGraphData(serviceRow, fileIndex) {
            this.graphLoading = true
            if (this.activeService == null) {
                this.graphDiv = null
                this.graphLoading = false
            }
            else {
                let blob_name = serviceRow.journey_plot_filenames[fileIndex]
                axios({
                    url: `${process.env.VUE_APP_API_SERVER}/individual/train-service-journey-plot`,
                    method: "GET",
                    params: {blob_name: blob_name},
                    headers: {"accept": "application/json",
                            "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                    }
                })
                .then(response => {
                    this.graphDiv = response.data
                    this.graphLoading = false
                })
                .catch( error => {
                    console.log(error)
                    if (error.response) {
                        console.log(error.response.status)
                    }
                })
            }
        },
    },
    created() {
        this.getServiceImpactData()
        this.getOperatorImpactData()
    }
}
</script>

<style scoped>
#journeys-card {
    height: 100%;
    margin-left: 0;
    margin-right: 0;
}
.body {
    display: flex;
    flex-direction: row;
    height: 100%;
}
.table {
    flex-basis: 0;
    flex-grow: 1;
    padding: 1rem 1rem 1rem 0;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
}
.graph {
    flex-basis: 0;
    flex-grow: 1.5;
}
.toc-data:hover, .service-row:hover, .dropdown-button:hover {
    cursor: pointer;
    background-color: #DBDDE6;
}
/* Rounding borders of table --------------------------------------------- */
    .toc-row:first-child .toc-data, .toc-data.has-top-gap {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
    }
    .toc-row:last-child .toc-data:not(.open) {
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
    }
/* ----------------------------------------------------------------------- */

.service-data {
    display: flex;
    flex-direction: row;
}
.service-table {
    flex-basis: 0;
    flex-grow: 1;
}

/* Styling Toc row (toc header)------------------------------------------- */
    .toc-data {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border: solid;
        border-width: 1px;
        border-color: var(--colour-2);
        padding: 1rem 0.5rem 1rem 0.5rem;
    }
    .toc-data .left, .toc-data .right {
        display: flex;
        align-items: center;
    }
    .toc-name, .toc-impact {
        font-size: 1.1rem;
        color: var(--colour-1);
    }
    .toc-name {
        font-weight: bold;
        text-align: center;
    }
    .toc-data i {
        font-size: 0.3rem;
        color: var(--colour-1);
        padding-top: 0.2rem;
        margin: 0 0.5rem 0 0.5rem;
    }
    :deep(.punctuality-text) {
        font-size: 1.1rem;
        color: var(--colour-1);
    }
    :deep(.percentage) {
        font-size: 1.1rem;
        color: var(--colour-7);
        font-weight: bold;
    }
    .dropdown-button {
        background-color: transparent;
        border: 0;
        outline: 0;
    }
    .dropdown-button i {
        font-size: 1.5rem;
    }
/* ----------------------------------------------------------------------- */

/* Styling for service row ----------------------------------------------- */
    .service-unavailable-text {
        text-align: center;
        margin: 0.8rem 0 0.8rem 0;
        font-size: 0.95rem;
        color: var(--colour-1);
        font-weight: 300;
    }
    .service-data {
        border: solid;
        border-width: 1px;
        border-color: var(--colour-2);
        border-top-width: 0;
        margin-bottom: 0.8rem;
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
    }
    .service-info i {
        font-size: 0.2rem;
        color: var(--colour-1);
        padding-top: 0.1rem;
        margin: 0 0.5rem 0 0.5rem;
    }
    .service-row:last-child {
        border-bottom-left-radius: 0.25rem;
    }
    .service-row.active {
        background-color: #DBDDE6;
        border-color: #192460;
        border-top-width: 1px;
    }
    .service-row {
        border: solid;
        border-width: 1px;
        border-color: var(--colour-2);
        border-top-width: 0;
        padding: 1rem 0.5rem 1rem 0.5rem;
        font-size: 0.95rem;
        color: var(--colour-1);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .service-row .radio {
        font-size: 0.7rem;
    }
    .service-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 0.5rem;
    }
    .service-code {
        font-weight: 500;
    }
    .service-route {
        font-weight: 300;
    }
    .service-impact {
        font-weight: 300;
        font-size: 0.85rem;
    }
    .service-train-count {
        font-weight: 300;
        font-size: 0.85rem;
        margin-left: 1rem;   
    }
    .service-row > i {
        margin-right: 1.1rem;
    }
/* ----------------------------------------------------------------------- */

/* Graph and graph controls ---------------------------------------------- */
    /* Need this to make height of plot responsive. for some reason when 100%, it just defaults to 450px */
    :deep(.js-plotly-plot) {
        height: 28rem !important;
        /* width: 15rem !important; */
    }
    .graph-header {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.5rem;
        height: 1.5rem;
    }
    .controls {
        display: flex;
        align-items: center;
    }
    .plot-train-count {
        font-size: 0.8rem;
        margin-left: 0.5rem;
        margin-top: 0.1rem;
    }
    .controls > button {
        background-color: transparent;
        padding: 0.3rem 0.3rem 0.2rem 0.3rem;
        margin: 0 0.2rem 0 0.2rem;
        border: 0;
        border-radius: 0.25rem;
    }
    .controls > button:hover {
        cursor: pointer;
        background-color: #DBDDE6;
    }
    .controls i {
        font-size: 1rem;
        color: var(--colour-1);
    }
/* ----------------------------------------------------------------------- */
</style>