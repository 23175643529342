<!--
    Actions card on Overview tab of Individual page
-->

<template>
<Card class="actions">
    <template #header-left>
        Actions
    </template>
    <template #body>
        <div class="scroll-content">
            <div v-if="loadingActionsListData">LOADING</div>
            <div v-else-if="actionsListData == null" class="no-actions-assigned-text">No actions assigned</div>
            <div v-else v-for="(row, index) in actionsListData" :key="index" class="actions-item">
                <div class="top">
                    <span class="left">
                        <i class="sprint-profile-inverted"/>
                        {{ row.responsible }}
                    </span>
                    <span class="right" v-html="formatActionStatus(row.status, row.is_comment, row.end_date)">
                    </span>
                </div>
                <div class="bottom">
                    <span class="left">
                        {{ row.description }}
                    </span>
                </div>
            </div>
        </div>
    </template>
</Card>
</template>

<script>
import Card from '../../common/Card.vue';
import axios from "axios";

export default {
    name: "Actions",
    components: {
        Card
    },
    data() {
        return {
            actionsListData: null,
            loadingActionsListData: true
        }
    },
    computed: {
        referenceNumber() {
            // current reference number for currently selected speed restriction
            let ref = this.$store.state.activeIndividualSpeedRestriction
            return ref
        },
        currentDate() {
            return new Date()
        },
    },
    watch: {
        referenceNumber: function() {
            this.getActionsListData()
        }
    },
    methods: {
        formatActionStatus(status, is_comment, end_date) {
            // only add end date if it exists
            // if status is complete, return that and green
            // if a comment, return grey and the comment status
            // if status is open and past end date, return 'overdue' and red
            // if status is open and not past end date, return 'open' and yellow
            let formatted_date = this.formatDate(end_date)
            end_date = formatted_date === "" ? new Date("4000-01-01") : new Date(end_date)
            let text;
            let colour;
            if (status === "complete") {
                text = "Complete"
                colour = "green"
            }
            else if (is_comment) {
                text = "Comment"
                colour = "grey"
            }
            else if (this.currentDate > end_date) {
                text = "Overdue"
                colour = "red"
            }
            else {
                text = "Open"
                colour = "yellow"
            }

            return `<span class="${colour} status">${text}${formatted_date}</span>`
        },
        formatDate(end_date) {
            if (end_date == null) {
                return ""
            }
            else {
                return ` - ${new Date(end_date).toLocaleDateString()}`
            }
        },
        getActionsListData() {
            this.loadingActionsListData = true
            axios({
                url: `${process.env.VUE_APP_API_SERVER}/actions/get-restriction-actions`,
                method: "GET",
                params: {reference: this.referenceNumber},
                headers: {"accept": "application/json",
                        "Authorization": `Bearer ${this.$store.state.authenticator.token}`
                }
            })
            .then(response => {
                this.actionsListData = response.data
                this.loadingActionsListData = false
            })
            .catch( error => {
                this.loadingActionsListData = false
                this.actionsListData = null
                console.log(error)
                if (error.response) {
                    console.log(error.response.status)
                }
            })
        },
    },
    created() {
        this.getActionsListData()
    },
    activated() {
        this.getActionsListData()
    }
}
</script>

<style scoped>
::v-deep .body {
    padding-right: 0;
}
.scroll-content {
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    padding-right: 1.5rem;
}
.actions {
    margin: 1rem 0 0 0;
    height: 0;
    flex-basis: 0;
    flex-grow: 1;
}
.actions-item {
    padding:  1rem 0 1rem 0;
}
.actions-item:not(:first-child) {
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: var(--colour-2);
}
.actions-item > .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}
.actions-item > .top > .left {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--colour-1);
}
.actions-item > .top > .left i {
    font-size: 1.8rem;
    margin-right: 0.5rem;
}
.actions-item > .top > .right :deep(.status) {
    font-size: 0.9rem;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    border-style: solid;
    border-width: 2px;
    border-radius: 0.5rem;
}
.actions-item > .top > .right :deep(.status.green) {
    color: var(--colour-8);
    border-color: var(--colour-8);
}
.actions-item > .top > .right :deep(.status.grey) {
    color: var(--colour-11);
    border-color: var(--colour-11);
}
.actions-item > .top > .right :deep(.status.red) {
    color: var(--colour-7);
    border-color: var(--colour-7);
}
.actions-item > .top > .right :deep(.status.yellow) {
    color: var(--colour-5);
    border-color: var(--colour-5);
}
.actions-item > .bottom> .left {
    font-size: 0.8rem;
}
.no-actions-assigned-text {
    font-size: 1.25rem;
    margin-top: 2rem;
    text-align: center;
}
</style>