
<template>
    <div class="kpis">
        <div class="tsrs">
            <div class="kpi timeloss tsr">
                <img src="@/assets/history.svg">
                <span class = kpi-block>
                    <div class="title">TSR time loss per day</div>
                    <div v-if="loading.timeloss" class="value">LOADING</div>
                    <div v-else>
                        <div class="value">{{ formatImpact(data.timeloss.tsr_total_daily_impact_minutes) }}</div>
                        <div class="description">
                            <span v-if="data.timeloss.tsr_arrow_direction == null" class="grey">
                                <i class="pi pi-minus"/>
                                <span class="change">  unknown change <br> 
                                    <span class = "string"> from last week </span> 
                                </span>                            
                            </span>
                            <span v-else-if="data.timeloss.tsr_arrow_direction === 'Horizontal'" class="yellow">
                                <i class="pi pi-arrow-right"/>
                                <span class="change">no change <br> 
                                    <span class = "string"> from last week </span> 
                                </span>
                            </span>
                            <span v-else-if="data.timeloss.tsr_arrow_direction === 'Up'" class="red">
                                <i class="pi pi-arrow-up"/>
                                <span class="change">{{ formatImpact(data.timeloss.tsr_impact_change_minutes) }} more <br> 
                                    <span class = "string"> than last week </span> 
                                </span>
                            </span>
                            <span v-else-if="data.timeloss.tsr_arrow_direction === 'Down'" class="green">
                                <i class="pi pi-arrow-down"/>
                                <span class="change">{{ formatImpact(data.timeloss.tsr_impact_change_minutes) }} less <br>
                                    <span class = "string"> than last week </span> 
                                </span>
                            </span>
                        </div>
                    </div>
                </span>
            </div>
            <div class="kpi speedrestrictions tsr">
                <img class = "speed-restriction-img" src="@/assets/quantity.svg">
                <span class = kpi-block>
                    <div class="title">Active TSRs</div>
                    <div v-if="loading.speedrestrictions" class="value">LOADING</div>
                    <div v-else>
                        <div class="value">{{ formatTsrRestrictionsCount(data.speedrestrictions.tsr_current_speed_restriction_count) }}</div>
                        <div class="description">
                            <span v-if="data.speedrestrictions.tsr_arrow_direction == null" class="grey">
                                <i class="pi pi-minus"/>
                                <span class="change">unknown change <br> 
                                    <span class = "string"> from last week </span> 
                                </span>  
                            </span>
                            <span v-else-if="data.speedrestrictions.tsr_arrow_direction === 'Horizontal'" class="yellow">
                                <i class="pi pi-arrow-right"/>
                                <span class="change">no change  <br> 
                                    <span class = "string"> from last week </span> 
                                </span>
                            </span>
                            <span v-else-if="data.speedrestrictions.tsr_arrow_direction === 'Up'" class="red">
                                <i class="pi pi-arrow-up"/>
                                <span class="change">{{ data.speedrestrictions.tsr_speed_restriction_count_change }} more <br>
                                    <span class = "string"> than last week </span>                             
                                </span>
                            </span>
                            <span v-else-if="data.speedrestrictions.tsr_arrow_direction === 'Down'" class="green">
                                <i class="pi pi-arrow-down"/>
                                <span class="change">{{ data.speedrestrictions.tsr_speed_restriction_count_change }} less <br>
                                    <span class = "string"> than last week </span>  
                                </span>
                            </span>
                        </div>
                    </div>
                </span>
            </div> 
        </div>      
        <Spacer v-if="this.$store.state.routesUsingEsrTool.includes(headerText)" :width="1"/>
        <Spacer v-else :width="0"/>
        <div class="esrs">
            <div v-if="this.$store.state.routesUsingEsrTool.includes(headerText)" class="kpi timeloss esr">
                <img src="@/assets/history.svg">
                <span class = kpi-block>
                    <div class="title">ESR time loss per day</div>
                    <div v-if="loading.timeloss" class="value">LOADING</div>
                    <div v-else>
                        <div class="value">{{ formatImpact(data.timeloss.esr_total_daily_impact_minutes) }}</div>
                        <div class="description">
                            <span v-if="data.timeloss.esr_arrow_direction == null" class="grey">
                                <i class="pi pi-minus"/>
                                <span class="change">unknown change <br> 
                                    <span class = "string"> from last week </span> 
                                </span>
                            </span>
                            <span v-else-if="data.timeloss.esr_arrow_direction === 'Horizontal'" class="yellow">
                                <i class="pi pi-arrow-right"/>
                                <span class="change">no change <br> 
                                    <span class = "string"> from last week </span> 
                                </span>
                            </span>
                            <span v-else-if="data.timeloss.esr_arrow_direction === 'Up'" class="red">
                                <i class="pi pi-arrow-up"/>
                                <span class="change">{{ formatImpact(data.timeloss.esr_impact_change_minutes) }} more <br>
                                    <span class = "string"> than last week </span>  
                                </span>
                            </span>
                            <span v-else-if="data.timeloss.esr_arrow_direction === 'Down'" class="green">
                                <i class="pi pi-arrow-down"/>
                                <span class="change">{{ formatImpact(data.timeloss.esr_impact_change_minutes) }} less <br>
                                    <span class = "string"> than last week </span>  
                                </span>
                            </span>
                        </div>
                    </div>
                </span>
            </div>
            <div v-if="this.$store.state.routesUsingEsrTool.includes(headerText)" class="kpi speedrestrictions esr">
                <img src="@/assets/quantity.svg">
                <span  class = kpi-block>
                    <div class="title">Active ESRs</div>
                    <div v-if="loading.speedrestrictions" class="value">LOADING</div>
                    <div v-else>
                        <div class="value">{{ formatEsrRestrictionsCount(data.speedrestrictions.esr_current_speed_restriction_count) }}</div>
                        <div class="description">
                            <span v-if="data.speedrestrictions.esr_arrow_direction == null" class="grey">
                                <i class="pi pi-minus"/>
                                <span class="change">unknown change <br> 
                                    <span class = "string"> from last week </span> 
                                </span>  
                            </span>
                        
                            <span v-else-if="data.speedrestrictions.esr_arrow_direction === 'Horizontal'" class="yellow">
                                <i class="pi pi-arrow-right"/>
                                <span class="change">no change <br> 
                                    <span class = "string"> from last week </span> 
                                </span>                         
                            </span>
                            <span v-else-if="data.speedrestrictions.esr_arrow_direction === 'Up'" class="red">
                                <i class="pi pi-arrow-up"/>
                                <span class="change">{{ data.speedrestrictions.esr_speed_restriction_count_change }} more <br>
                                    <span class = "string"> than last week </span>  
                                </span>
                            </span>
                            <span v-else-if="data.speedrestrictions.esr_arrow_direction === 'Down'" class="green">
                                <i class="pi pi-arrow-down"/>
                                <span class="change">{{ data.speedrestrictions.esr_speed_restriction_count_change }} less <br>
                                    <span class = "string"> than last week </span>  
                                </span>
                            </span>
                        </div>
                    </div>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import Spacer from "@/components/home/common/Spacer.vue"
import Formatter from "@/functions/formatter"

export default {
    name: 'Kpis',
    components: {
        Spacer
    },
    props: {
        timeLossData: {
            type: Promise,
            required: true
        },
        speedRestrictionsCountData: {
            type: Promise,
            required: true
        },
        headerText: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            data: {
                timeloss: null,
                speedrestrictions: null
            },
            loading: {
                timeloss: true,
                speedrestrictions: true,
            }
        }
    },
    watch: {
        timeLossData(promise) {
            this.loading.timeloss = true;
            promise.then(data => {
                this.data.timeloss = data;
                this.loading.timeloss = false;
            })
        },
        speedRestrictionsCountData(promise) {
            this.loading.speedrestrictions = true;
            promise.then(data => {
                this.data.speedrestrictions = data;
                this.loading.speedrestrictions = false;
            })
        }
    },
    methods: {
        formatImpact(impactNum) {
            if (impactNum == null) {
                return "No Data Available"
            } else {
                impactNum = impactNum.toLocaleString("en-UK")
                return Formatter.formatUnit(impactNum, "minute")
            }
        },
        formatTsrRestrictionsCount(restrictionsCount) {
            if (restrictionsCount == null) {
                return "Unknown"
            } else {
                restrictionsCount = restrictionsCount.toLocaleString("en-UK")
                return Formatter.formatUnit(restrictionsCount, "TSR")
            }         
        },
        formatEsrRestrictionsCount(restrictionsCount) {
            if (restrictionsCount == null) {
                return "Unknown"
            } /*else if (restrictionsCount == 0) {
                return "No Data Available"
            } */ else {
                restrictionsCount = restrictionsCount.toLocaleString("en-UK")
                return Formatter.formatUnit(restrictionsCount, "ESR")
            }         
        }
    },
    created() {
        this.timeLossData.then(data => {
            this.data.timeloss = data;
            this.loading.timeloss = false;
        })
        this.speedRestrictionsCountData.then(data => {
            this.data.speedrestrictions = data;
            this.loading.speedrestrictions = false;
        })
    }
}
</script>

<style scoped>
.kpis {
    display: flex;
    height: 5.5rem;
    width: 70%
}
.kpi {
    display: flex;
    align-items: center;
    /* Sets flex items to equal width */
    flex-grow: 0;
    flex-basis: auto;
    margin-left: 0rem;
    margin-right: 0.5rem;
}
.kpi > img {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 0.5rem;
    color: var(--colour-1)
}
.kpi .title {
    font-size: 0.9rem;
    color: var(--colour-6);
    white-space: nowrap;
}
.kpi .value {
    font-weight: 600;
    font-size: 1.0rem;
    color: var(--colour-6);
    white-space: nowrap;
}
.kpi .description {
    font-size: 0.9rem;
    font-weight: 500;
    color: var(--colour-6);
}
.description > span {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
}
.description i {
    border-radius: 100%;
    padding: 0.3rem;
    font-weight: bold;
    font-size: 0.6rem;
    color: var(--colour-4);
    margin-right: 0.25rem;
}
.description .change {
    font-weight: bold;
    font-size: 0.8rem;
}
.description .red .change {
    color: var(--colour-7);
}
.description .red i {
    background-color: var(--colour-7);
}
.description .yellow .change {
    color: var(--colour-5);
}
.description .yellow i {
    background-color: var(--colour-5);
}
.description .green .change {
    color: var(--colour-8);
}
.description .green i {
    background-color: var(--colour-8);
}
.description .grey .change {
    color: grey;
}

.string {
    color: initial;
    font-weight: normal;
}
.description .grey i {
    background-color: grey;
}

.speed-restriction-img {
    margin-left: 0.5rem;
}

.kpi-block {
    margin: auto;
}
.tsrs {
    display: flex;
}

.esrs {
    display: flex;
}

</style>
