<!--
    Toc table view on Operator Impact Card on Overview tab of Individual page
-->

<template>
<DataTable :value="data" :autoLayout="true">
    <Column field="trains_impacted_per_day" header="Daily train count">
        <template #body={data}>
            <div style="display:flex;justify-content:center;">
                <span class="bubble" :style="getBubbleSizeStyle(data.trains_impacted_per_day)">
                    <b>{{formatTrainCount(data.trains_impacted_per_day)}}</b>
                </span>
            </div>
        </template>
    </Column>
    <Column field="daily_impact_minutes" header="Daily time loss">
    <template #body={data}>
        <div class="toc">
            {{ data.toc }}
        </div>
        <div class="stats">
            <span class="bar" :style="getBarSizeStyle(data.daily_impact_minutes)">
                <b>{{formatMinutes(data.daily_impact_minutes)}}</b>
            </span>
            <span>{{data.daily_impact_seconds_per_train}}s per train</span>
        </div>
    </template>
    </Column>
    <Column field="would_have_been_ontime">
    <template #header>
        <div class="punctuality-header">
            <div class="topheader">
                Potential increase on punctuality by removal
            </div>
            <div class="subheaders">
                <span>
                    On-Time
                </span>
                <span>
                    T-3
                </span>
            </div>
        </div>
    </template>
    <template #body={data}>
        <div class="punctuality-cell">
            <span class="ontime" 
                v-html="formatPunctuality(
                    data.would_have_been_ontime,
                    data.trains_impacted_per_day,
                    data.daily_impact_minutes
                )"
            />
            <span class="t3" 
                v-html="formatPunctuality(
                    data.would_have_been_t3,
                    data.trains_impacted_per_day,
                    data.daily_impact_minutes
                )"
            />
        </div>
    </template>
    </Column>
</DataTable>
</template>

<script>
import DataTable from 'primevue/datatable/sfc';
import Column from 'primevue/column/sfc';

export default {
    name: 'TocTable',
    components: {
        DataTable,
        Column
    },
    props: ['data'],
    data() {
        return {
            largestBubbleSizeRem: 5,
            largestBarSizeRem: 14
        }
    },
    computed: {
        rem() {
            return parseFloat(getComputedStyle(document.documentElement).fontSize)
        },
        bubbleSizeToRemConversion() {
            let maxBubbleSize = 0
            for (let row of this.data) {
                if (row.trains_impacted_per_day > maxBubbleSize) {
                    maxBubbleSize = row.trains_impacted_per_day
                }
            }
            return this.largestBubbleSizeRem / maxBubbleSize
        },
        barSizeToRemConversion() {
            let maxBarSize = 0
            for (let row of this.data) {
                if (row.daily_impact_minutes > maxBarSize) {
                    maxBarSize = row.daily_impact_minutes
                }
            }
            return this.largestBarSizeRem / maxBarSize
        },
    },
    methods: {
        getBubbleSize(dailyTrainCount) {
            //convert train count to size in rem
            let size = dailyTrainCount * this.bubbleSizeToRemConversion
            return size
        },
        getBubbleSizeStyle(dailyTrainCount) {
            let size = this.getBubbleSize(dailyTrainCount)
            return {height: size + 'rem', width: size + 'rem', fontSize: (size * 0.4) + 'rem'}
        },
        formatTrainCount(count) {
            if (this.getBubbleSize(count) < (this.largestBubbleSizeRem * 0.2)) {
                return null
            }
            else {
                return count
            }
        },
        getBarSize(dailyTimeloss) {
            //convert time loss to size in rem
            let size = dailyTimeloss * this.barSizeToRemConversion
            return size
        },
        getBarSizeStyle(dailyTimeloss) {
            return {width: this.getBarSize(dailyTimeloss) + 'rem'}
        },
        formatMinutes(minutes) {
            if (this.getBarSize(minutes) < (this.largestBarSizeRem * 0.45)) {
                return null
            }

            if (minutes == null) {
                minutes = "Unknown"
            }
            else {
                minutes = minutes.toLocaleString("en-UK")
                if (minutes == 1) {
                    minutes += " minute"
                }
                else {
                    minutes += " minutes"
                }
            }
            return minutes
        },
        formatPunctuality(percentage, train_count, impact) {
            if (percentage == null) {
                if (train_count == 0) {
                    return "<span class='punctuality-text'>No trains</span>"
                }
                else if (impact == 0) {
                    return "<span class='punctuality-text'>No impact</span>"
                }
                else {
                    return null
                }
            }
            else {
                return `<span class="punctuality-text"><span class="percentage">${percentage}%</span> of trains</span>`
            }
        },
    }
}
</script>

<style scoped>
.bubble {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--colour-4);
    background-color: var(--colour-1);
    border-radius: 50%;
}
.bar {
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    color: var(--colour-4);
    background-color: var(--colour-1);
    height: 1.5rem;
    border-radius: 0.2rem;
    margin-right: 0.5rem;
    padding-right: 0.5rem;
}
.toc {
    font-weight: bold;
    font-size: 1.6rem;
    color: var(--colour-1);
}
.punctuality-header {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.punctuality-header > .topheader {
    border-bottom-style: solid;
    border-color: var(--colour-2);
    border-bottom-width: 1px;
    padding-bottom: 0.5rem;
}
.punctuality-header > .subheaders {
    padding-top: 0.5rem;
    display: flex;
}
.punctuality-header > .subheaders > span {
    flex-basis: 0;
    flex-grow: 1;
}
.punctuality-cell {
    display: flex;
    flex-direction: row;
}
.punctuality-cell > span {
    flex-basis: 0;
    flex-grow: 1;
}
:deep(.punctuality-text) {
    font-size: 1.25rem;
}
:deep(.percentage) {
    font-size: 1.25rem;
    color: var(--colour-7);
    font-weight: bold;
}
</style>