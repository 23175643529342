<!-- Common vue component used for spacer -->

<template>
    <div class="spacer" :style="divStyling"></div>
</template>

<script>
export default {
    name: "Spacer",
    props: ["width"],
    computed: {
        divStyling() {
            return {
                "margin": `0 ${this.width}rem 0 ${this.width}rem`
            }
        }
    }
}
</script>

<style scoped>
.spacer {
    height: 100%;
    width: 0;
    border-style: solid;
    border-width: 1px;
    border-color: var(--colour-11);
}
</style>