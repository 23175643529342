<template>
    <span id="view-selection">
        <Button :class="{active: activeType === 'region'}" @click="buttonClick('region')">
            Region
        </Button>
        <Button :class="{active: activeType === 'route'}" @click="buttonClick('route')">
            Route
        </Button>
        <Button :class="{active: activeType === 'toc'}" @click="buttonClick('toc')">
            Operator
        </Button>
    </span>
</template>

<script>
import Button from "src/components/home/common/Button.vue"

export default {
    name: 'ViewSelection',
    components: {
        Button
    },
    props: {
        activeType: {

        }
    },
    methods: {
        buttonClick(view) {
            if (view !== this.activeType) {
                this.$router.push(`/home/route_operator/${view}`)
            }
        }
    }
}
</script>

<style scoped>
#view-selection {
    display: flex;
    flex-direction: column;
    width: 7rem;
    height: 100%;
    justify-content: space-between;
}
</style>