<!-- HomePage of the app -->

<template>
  <div></div>
</template>

<script>

export default {
  name: 'PathNotFound',
  created() {
    this.$router.replace(`/home/overview`)
  }
}
</script>

<style scoped>
</style>
