<!-- Common vue component used for timeloss pop up -->

<template>
    <i class="pi pi-question-circle" 
        v-tooltip.bottom="'Click for explanation on Unknown time loss'"
        @click="dialogVisibility=true"
    />
    <Dialog v-model:visible="dialogVisibility" modal :header="setHeader" class="custom-dialog">
        <div v-if="dailyTimeloss" class="p-text-secondary block mb-5 timeloss">
            <p>
            The primary reason a speed restriction may display 'Unknown' daily time loss is a lack of sufficient 
            train movement data before and/or during the speed restriction. Lack of train movement data 
            prevents SPRINT from calculating time loss for the speed restriction.
            <br/>
            <br/>
            Speed restrictions predating 01 January 2019 will display 'Unknown' time loss by default
            as SPRINT only has train movement data from 01 January 2019 onwards.
            </p>
        </div>
        <div v-else class="p-text-secondary block mb-5 timeloss">
            <p>
            Cumulative time loss is unknown because daily time loss is 'Unknown'.
            Select the icon next to 'Unknown' in the daily time loss column for more information.
            </p>
        </div>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog'

export default {
    name: "TimelossExplainer",
    components: {
        Dialog
    },
    props: {
        dailyTimeloss: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            dialogVisibility: false,
        }
    },
    computed: {
        setHeader() {
            return this.dailyTimeloss ? "Unknown Daily Time Loss" : "Unknown Cumulative Time Loss"
        }
    }
}
</script>

<style scoped>
.timeloss {
    text-align: justify;
    width: 30rem;
}

.button {
    display: flex;
    justify-content: flex-end;
}

</style>