import axios from "axios"

// Authenticator as a vuex module
const vuexAuthenticator = {
    namespaced: true,
    state: () => ({
        token: null,
        username: null,
        response_status_get_token: null,
        response_status_verify_token: null
    }),
    mutations: {
        setToken(state, token) {
            state.token = token
        },
        setUsername(state, username) {
            state.username = username
        },
        setResponseStatusGetToken(state, status) {
            state.response_status_get_token = status
        },
        setResponseStatusVerifyToken(state, status) {
            state.response_status_verify_token = status
        }
    },
    actions: {
        async getToken(context, {username, password}) {
            await axios({
                url: `${process.env.VUE_APP_API_SERVER}/auth/token`,
                method: "POST",
                data: `username=${username}&password=${password}`,
                headers: {"accept": "application/json",
                        "Content-Type": "application/x-www-form-urlencoded"}
            })
            .then(response => {
                context.commit("setToken", response.data.access_token)
                context.commit("setUsername", username)
                context.commit("setResponseStatusGetToken", response.status)
            })
            .catch( error => {
                console.log(error)
                if (error.response) {
                    context.commit("setResponseStatusGetToken", error.response.status)
                }
                else {
                    context.commit("setResponseStatusGetToken", 0)
                }
            })
        },
        async verifyToken(context) {
            //
            // check token exists
            if (context.state.token) {
                await axios({
                    url: `${process.env.VUE_APP_API_SERVER}/auth/verify`,
                    method: "GET",
                    headers: {"accept": "application/json",
                            "Authorization": `Bearer ${context.state.token}`
                    }
                })
                .then(response => {
                    if (response.status == 200) {
                        context.commit("setResponseStatusVerifyToken", response.status)
                    }
                    else {
                        context.commit("setResponseStatusVerifyToken", response.status)
                    }
                })
                .catch( error => {
                    console.log(error)
                    if (error.response) {
                        context.commit("setResponseStatusGetToken", error.response.status)
                    }
                    else {
                        context.commit("setResponseStatusGetToken", 0)
                    }
                })
            }
            else {
                context.commit("setResponseStatusVerifyToken", null)
            }
        },
        removeAuthorisation(context) {
            context.commit("setToken", null)
            context.commit("setUsername", null)
        }
    },
}

export {vuexAuthenticator}