<!-- General component used to display the KPIs -->

<template>
    <div class="kpi">
        <img :src="svgSource">
        <div class="data">
            <div class="title">
                <slot name="title"/>
            </div>
            <div class="value">
                <slot name="value"/>
            </div>
            <div class="description">
                <slot name="description"/>
            </div>
            <div class="subtitle_1">
                <slot name="subtitle_1"/>
            </div>
            <div class="subtitle_2">
                <slot name="subtitle_2"/>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Kpi',
    props: ['svgSource'],
}
</script>

<style scoped>
.kpi {
    padding: 0.5rem 0 0.5rem 1rem;
    background-color: var(--colour-4);
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: flex;
    align-items: center;
    max-width: 24rem;
    /* Sets flex items to equal width */
    flex-grow: 1;
    flex-basis: 0;
}
img {
    margin-right: 0.75rem;
    width: 3.5rem;
    height: 3.5rem;
}
.title, .description, .subtitle_1, .subtitle_2 {
    color: var(--colour-6)
}
.value {
    color: var(--colour-1)
}
.title, .value {
    font-size: 1.4rem;
    font-weight: bold;
}
.description, .subtitle_1, .subtitle_2 {
    font-size: 0.8rem;
}

</style>
