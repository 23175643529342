/**
 * Namespace for reference number conversion functions
 */
class referenceNumberConversion {

    /**
     * Converts a reference number to a URI supported format
     * @param {string} reference the reference number to convert 
     * @returns {string} the reference number in URI supported format
     */
    static referenceToUriReference(reference) {
        if (reference) {
            return reference.replace("/", "-")
        }
    }

    /**
     * Converts a URI formatted reference number into how it appears in TSR register
     * @param {string} uri_reference the reference number in URI format
     * @returns {string} the reference number as it appears in TSR register
     */
    static uriReferenceToReference(uri_reference) {
        if (uri_reference) {
            return uri_reference.replace("-", "/")
        }
    }
}

export default referenceNumberConversion