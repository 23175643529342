<!-- Displays content for the Route View  -->

<template>
    <div class="container">
        <div class="left">
            <div class="header-bar">
                <ViewSelection :activeType="view"/>
                <Spacer :width="1"/>
                <SearchDropdown id="search-dropdown"
                    :itemListPromise="selectionList"
                    :headerText="selected"
                    :activeItem="selected"
                    @itemSelected="selectionClicked"
                    :headerMaxWidth="10"
                    :headerNoWrap="false"
                    :dropdownWidth="200"
                />
                <Spacer :width="0.75"/>
                <Kpis
                    :timeLossData="kpiTimeLossData"
                    :speedRestrictionsCountData="kpiSpeedRestrictionsCountData"
                    :headerText="selected"
                />
            </div>
            <SpeedRestrictionRegister class="card" :tableData="registerData"/>
        </div>
        <div class="right">
             <TreemapChart class="card"
                :data="treeMapChartData"
                v-bind="treeMapChartProps"
             />
            <BarGraph class="card"
                :primaryData="barGraphPrimaryData"
                :secondaryData="barGraphSecondaryData"
                v-bind="barGraphProps"
            />
        </div>
    </div>
</template>

<script>
import BarGraph from './common/BarGraph.vue'
import SpeedRestrictionRegister from './common/SpeedRestrictionRegister.vue'
import SearchDropdown from "src/components/home/common/SearchDropdown.vue"
import TreemapChart from "./common/TreemapChart.vue"
import ViewSelection from "./common/ViewSelection.vue"
import Kpis from "./common/Kpis.vue"
import Handler from "./selected_content_handler.js"
import Spacer from "@/components/home/common/Spacer.vue"

export default {
    name: 'SelectedContent',
    components: {
        BarGraph,
        SpeedRestrictionRegister,
        SearchDropdown,
        TreemapChart,
        ViewSelection,
        Kpis,
        Spacer
    },
    props: {
        // the view to display (can be 'route', 'toc' or 'region')
        view: {
            type: String,
            required: true
        },
        // the active selection for the view (e.g. a route/toc/region name)
        selected: {
            type: String,
            required: true
        }
    },
    computed: {
        authToken() {
            return this.$store.state.authenticator.token
        },
        barGraphProps() {
            return Handler.barGraphProps[this.view]
        },
        treeMapChartProps() {
            return Handler.treeMapChartProps[this.view]
        },
        registerData() {
            return Handler.registerData[this.view](
                this.selected, this.authToken
            )
        },
        selectionList() {
            return Handler.selectionList[this.view](this.authToken)
        },
        barGraphPrimaryData() {
            return Handler.barGraphPrimaryData[this.view](
                this.selected, this.authToken
            )
        },
        barGraphSecondaryData() {
            return Handler.barGraphSecondaryData[this.view](
                this.selected, this.authToken
            )
        },
        treeMapChartData() {
            return Handler.treeMapChartData[this.view](
                this.selected, this.authToken
            )
        },
        kpiSpeedRestrictionsCountData() {
            return Handler.kpiSpeedRestrictionsCountData[this.view](
                this.selected, this.authToken
            )
        },
        kpiTimeLossData() {
            return Handler.kpiTimeLossData[this.view](
                this.selected, this.authToken
            )
        }
    },
    methods: {
        selectionClicked(selection) {
            Handler.selectionClicked[this.view](selection, this.$router)
        }
    }
}
</script>


<style scoped>
.container {
    height: 100%;
    display: flex;
    flex-direction: row;
}
.header-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 5.5rem;
    margin: 0 0.75rem 0.75rem 0.5rem;
}
.left {
    display: flex;
    flex-direction: column;
    flex-grow: 1.8;
    flex-basis: 0;
    width: 0;  /* need this so sr register can grow to correct width */
}
.right {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
}
.card {
    flex-grow: 1;
    flex-basis: 0;
    height: 0;  /* need this so charts can grow to correct height */
}
</style>
