import { createRouter, createWebHashHistory } from 'vue-router';
import HomePage from './components/home/HomePage.vue'
import IndvidualContent from './components/home/individual/IndividualContent.vue'
import LoginPage from './components/login/LoginPage.vue'
import ManagementContent from './components/home/management/ManagementContent.vue'
import OverviewContent from './components/home/overview/OverviewContent.vue'
import PathNotFound from './components/PathNotFound.vue'
import RouteOperatorPage from './components/home/route_operator/RouteOperatorPage.vue'

function generateRouter(store) {
    // Define the routes
    const routes = [
        { path: '/login', component: LoginPage },
        {
            path: '/home', component: HomePage,
            children: [
                { path: '', component: OverviewContent },
                { path: 'overview', component: OverviewContent },
                {
                    path: 'route_operator/:view?/:selected?',
                    component: RouteOperatorPage
                },
                {
                    path: 'individual/:tab?/:reference?',
                    component: IndvidualContent
                },
                {
                    path: 'management',
                    component: ManagementContent
                }
            ]
        },
        { path: '/:pathMatch(.*)*', component: PathNotFound }
    ]

    // Create router instance
    const router = createRouter({
        // Provide the history implementation to use
        history: createWebHashHistory(),
        routes: routes,
    })

    // Add navigation guards
    router.beforeEach(async (to) => {
        // do only if it doesn't have a redirected_from object
        if (to.redirectedFrom === undefined) {
            // verify token
            await store.dispatch("authenticator/verifyToken")
            // get response and set to null in store
            const response_status = store.state.authenticator.response_status_verify_token
            store.commit("authenticator/setResponseStatusVerifyToken", null)
            // check whether response to token verification was ok
            if (response_status === 200 && to.path === "/login") {
                // redirect to home page if already logged in
                return "/home"
            }
            else if (response_status === 200) {
                return true
            }
            else {
                return "/login"
            }
        }
        else {
            return true
        }
    })

    return router
}

export { generateRouter }
